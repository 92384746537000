<div id="id01" class="modal contact-modal">
    <div class="modal-inner">
        <form class="ContactForm modal-content animate" [formGroup]="ContactForm" method="post">
            <div class="align-left cancel-div-login">
                <h3 class="text-center contactFormHeader">Come on board as an official partner and get custom prices for
                    your enterprise.</h3>
                <button type="button" onclick="document.getElementById('id01').style.display='none'"
                    class="cancelbtn">X</button>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <label for="psw"><b>Name</b></label>
                        <input formControlName="name" id="contact-name" type="text" placeholder="Nelson Mandela"
                            name="name" required>
                        <div class="alert alert-danger"
                            *ngIf="ContactForm.controls['name'].invalid && (ContactForm.controls['name'].dirty || ContactForm.controls['name'].touched || submitted)">
                            Please provide your Name</div>
                    </div>
                    <div class="col-md-6">
                        <label for="email"><b>Email</b></label>
                        <input type="text" formControlName="email" id="login-email"
                            placeholder="Enter your email or Phone number" name="email" required>
                        <div class="alert alert-danger"
                            *ngIf="ContactForm.controls['email'].invalid && (ContactForm.controls['email'].dirty || ContactForm.controls['email'].touched || submitted)">
                            Your Email is missing</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <label for="contact-message"><b>Message</b></label>
                        <textarea formControlName="message" name="message" id="contact-message"
                            class="border form-control" rows="10"></textarea>
                        <div class="alert alert-danger"
                            *ngIf="ContactForm.controls['message'].invalid && (ContactForm.controls['message'].dirty || ContactForm.controls['message'].touched || submitted)">
                            Say something brief.</div>
                    </div>
                </div>

                <div *ngIf="submiterror" class="alert alert-danger">
                    <strong>Error!</strong>
                    Sorry something went wrong. Please try again.
                </div>
                <div *ngIf="success" class="alert alert-success">
                    <strong>Success!</strong>
                    Your message was received. You will hear from us soon.
                </div>
            </div>

            <div class="container" style="background-color:#f1f1f1">
                <!-- <button type="button" onclick="document.getElementById('id01').style.display='none'" class="cancelbtn">Cancel</button> -->
                <a href="#partner"><button type="submit" *ngIf="!loading" (click)="onSubmit()" name="submit-message"
                        class="register-btn submit-message">Send Message</button></a>
                <button type="submit" (click)="onSubmit()" name="submit-message" class="register-btn submit-message"
                    *ngIf="loading">Loading...</button>
                <!-- <div class="form-group">
                    <input type="submit" (click)="onSubmit()" value="Loading..." name="submit-message" class="form-control disabled submit-message" *ngIf="loading">
                </div> -->
            </div>
        </form>
    </div>
</div>
<div id="id-report-problem" class="modal contact-modal">
    <div class="modal-inner">
        <form class="ContactForm modal-content animate" [formGroup]="ReportProblemForm" method="post">
            <div class="align-left cancel-div-login">
                <h3 class="text-center contactFormHeader">Tell us about the issue and our team will get in touch
                    shortly.</h3>
                <button type="button" onclick="document.getElementById('id-report-problem').style.display='none'"
                    class="cancelbtn">X</button>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <label for="psw"><b>Name</b></label>
                        <input formControlName="name" id="contact-name" type="text" placeholder="Nelson Mandela"
                            name="name" required>
                        <div class="alert alert-danger"
                            *ngIf="ReportProblemForm.controls['name'].invalid && (ReportProblemForm.controls['name'].dirty || ReportProblemForm.controls['name'].touched || submitted)">
                            Please provide your Name</div>
                    </div>
                    <div class="col-md-6">
                        <label for="email"><b>Email</b></label>
                        <input type="text" formControlName="email" id="login-email"
                            placeholder="Enter your email or Phone number" name="email" required>
                        <div class="alert alert-danger"
                            *ngIf="ReportProblemForm.controls['email'].invalid && (ReportProblemForm.controls['email'].dirty || ReportProblemForm.controls['email'].touched || submitted)">
                            Your Email is missing</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <label for="contact-message"><b>Message</b></label>
                        <textarea formControlName="message" name="message" id="contact-message"
                            class="border form-control" rows="10"></textarea>
                        <div class="alert alert-danger"
                            *ngIf="ReportProblemForm.controls['message'].invalid && (ReportProblemForm.controls['message'].dirty || ReportProblemForm.controls['message'].touched || submitted)">
                            Say something brief.</div>
                    </div>
                </div>

                <div *ngIf="submiterror" class="alert alert-danger">
                    <strong>Error!</strong>
                    Sorry something went wrong. Please try again.
                </div>
                <div *ngIf="success" class="alert alert-success">
                    <strong>Success!</strong>
                    Your message was received. You will hear from us soon.
                </div>
            </div>

            <div class="container" style="background-color:#f1f1f1">
                <!-- <button type="button" onclick="document.getElementById('id01').style.display='none'" class="cancelbtn">Cancel</button> -->
                <button type="submit" *ngIf="!loading" (click)="onSubmit()" name="submit-message"
                    class="register-btn submit-message">Send Message</button>
                <button type="submit" (click)="onSubmit2()" name="submit-message" class="register-btn submit-message"
                    *ngIf="loading">Loading...</button>
                <!-- <div class="form-group">
                    <input type="submit" (click)="onSubmit()" value="Loading..." name="submit-message" class="form-control disabled submit-message" *ngIf="loading">
                </div> -->
            </div>
        </form>
    </div>
</div>