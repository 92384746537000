import { Component, OnInit } from '@angular/core';
import { config } from '../_helpers/global';
import { DevicesService } from '../_services/devices.service';
import { BroadcastService } from '../_services/broadcast.service';
import * as $ from 'jquery';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LoggedInService } from '../_services/logged-in-service.service';
import {
  fadeInLeftOnEnterAnimation, fadeInUpOnEnterAnimation,
} from 'angular-animations';

@Component({
  selector: 'app-myassets',
  templateUrl: './myassets.component.html',
  styleUrls: ['./myassets.component.css'],
  animations: [
    fadeInUpOnEnterAnimation({ anchor: 'enter1' })
  ]
})

export class MyassetsComponent implements OnInit {
  hash: any;
  cars = [];
  locations = [];
  loading = true;

  constructor(private DS: DevicesService, private router: Router, private BS: BroadcastService, private LS: LoggedInService) { }

  ngOnInit(): void {
    this.checkLoggedIn();
    //toggle laptop section
    $(document).ready(function () {
      $('#carsSectionToggle').on('click', function () {
        $('#carsSection').fadeIn('fast');
        $('#laptopsSection').fadeOut('fast');
        $('#laptopsSectionToggleHeader').removeClass('active');
        $('#carsSectionToggleHeader').addClass('active');
      });

      $('#laptopsSectionToggle').on('click', function () {
        $('#carsSection').fadeOut('fast');
        $('#laptopsSection').fadeIn('fast');
        $('#laptopsSectionToggleHeader').addClass('active');
        $('#carsSectionToggleHeader').removeClass('active');
      });
    });
  }

  checkLoggedIn() {
    if (this.LS.checkStatus() != 0) {
      this.hash = this.LS.checkStatus();
      this.getDevices(this.hash);
    } else {
      this.router.navigate(['/']);
    }
  }

  traceRoute(device_id) {
    // let car = this.cars[index];
    // let positions = car['device_data']['latest_positions'];
    // positions = positions.split(';');
    // let coordinatesarray = [];
    // for (let i=0; i < positions.length; i++) {
    //   let rawcoordinates = positions[i];
    //   rawcoordinates = rawcoordinates.split('/');
    //   let lat = parseFloat(rawcoordinates[0]);
    //   let long = parseFloat(rawcoordinates[1]);
    //   let coordinates = {lat: lat,lng: long};
    //   coordinatesarray.push(coordinates);
    // }
    // // console.log(coordinatesarray);
    // this.router.navigateByUrl('/home', { state: { route: coordinatesarray } });

    this.BS.toggleHistorySideBar.emit({ device: this.cars[device_id] });
  }

  toggleSidebar(header, tagline, device_id) {
    this.BS.toggleSideBar.emit({ header, tagline, device: this.cars[device_id] });
    // console.log(header+"+"+tagline);
  }

  getDevices(hash) {
    this.DS.getDevices(hash).subscribe(data => {
      // console.log(data);

      let all_devices = data[0]['items'];
      this.getCarsOnly(all_devices);
    }, error => {
      console.log(error);
    });
  }

  getCarsOnly(devices) {
    let name;
    for (let i = 0; i < devices.length; i++) {
      if (name = devices[i]['name']) {
        let regex = /[A-Z]{3}\s\d{3}\S$/g
        let test = regex.test(name);
        if (test) {
          var car = devices[i];
          // console.log(car);
          this.cars.push(car);
        }
      }
    }
    this.loading = false;
    // console.log(this.cars);
  }

  viewOnMap(i) {
    // console.log(this.cars[i]);
    this.router.navigateByUrl('/home', { state: { car: this.cars[i] } });
  }

  searchAssets() {
    var assetname = $('#search-assets').val();
    assetname = assetname.toUpperCase();
    var results = [];
    // if (assetname != '') {
    for (let i = 0; i < this.cars.length; i++) {
      let str = this.cars[i]['name'];
      str = str.toUpperCase();
      if (str.includes(assetname)) {
        results.push(i);
      }
    }
    // }
    this.hideDivs('car', results);
  }

  hideDivs(category, ids) {
    if (ids.length < 1) {
      $('#no-asset-error').fadeIn();
    } else {
      $('#no-asset-error').hide();
    }
    for (let i = 0; i < this.cars.length; i++) {
      if (!ids.includes(i)) {
        // console.log('yes');
        let str = '#' + category + '_' + i;
        $(str).hide();
      } else {
        let str = '#' + category + '_' + i;
        $(str).show();
      }
    }
  }
}