import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ContactServiceService {
  reportProblemURL = 'report_problem.php';
  contactURL = 'contact.php';
  registerURL = 'register.php';

  constructor(private http: HttpClient) { }

  contactUs(data) {
    return this.http.post(this.contactURL, { data: data }, { responseType: 'text' });
  }

  reportProblem(data) {
    return this.http.post(this.reportProblemURL, { data: data }, { responseType: 'text' });
  }

  register(data) {
    return this.http.post(this.registerURL, { data: data }, { responseType: 'text' });
  }
}