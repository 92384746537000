<div class="wrapper ">
  <!--sidebar-->
  <app-side-bar></app-side-bar>
  <!--sidebar End-->

  <div class="main-panel">
    <!-- Navbar -->
    <!-- <app-nav-bar></app-nav-bar> -->
    <!-- End Navbar -->

    <div *ngIf="!loading" class="content">
      <div class="container-fluid account-wrapper">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="card card-profile">
              <div class="card-avatar">
                <a href="javascript:;">
                  <img class="img" src="../assets/img/profile.jpg" />
                </a>
              </div>
              <div class="card-body">
                <!-- <h4 class="card-title">Alec Thompson</h4> -->
                <p class="card-description">
                  {{user['email']}}
                </p>
                <h6 class="card-category text-gray"><b>Membership: </b>{{user['plan']}}</h6>
                <h6 class="card-category text-gray"><b>Device Limit: </b>{{user['devices_limit']}}</h6>
                <p class="card-description">
                  <b *ngIf="user['days_left'] != null">{{user['days_left']}}</b> <b
                    *ngIf="user['days_left'] == null">infinite</b>
                  Days left
                </p>
                <!-- <a href="javascript:;" class="btn btn-primary btn-round">Renew Subscription</a> -->
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div *ngIf="loading" class="content">
      <div class="container-fluid">
        <div class="row">

          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="card card-profile">
              <div class="card-avatar">
                <a href="javascript:;">
                  <img class="img" src="../assets/img/profile.jpg" />
                </a>
              </div>
              <div class="card-body loader-wrapper text-center">
                <div class="spinner-block  pt-2">
                  <div class="spinner spinner-3"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>

    <!--footer-->
    <app-footer></app-footer>
    <!--footer END-->
  </div>
</div>