import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { } from 'googlemaps';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { DevicesService } from '../_services/devices.service';
import { BroadcastService } from '../_services/broadcast.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { LoggedInService } from '../_services/logged-in-service.service';

@Component({
  selector: 'app-geofences',
  templateUrl: './geofences.component.html',
  styleUrls: ['./geofences.component.css']
})
export class GeofencesComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;

  hash : any;
  mapProp: any;
  AddGeofenceForm: FormGroup;
  drawingManager: any;
  map: google.maps.Map;
  lat: number = -1.286389;
  lng: number = 36.817223;
  ListActive = false;
  PolygonActive = true;
  ClickedPolygon : any;
  PolygonValue : any;
  Geofences : any;
  ShapeDrawn = [];
  DrawnPolygons = [];
  AddSuccess = false;
  AddError = false;
  LoadingSubmit = false;
  GeofenceItemActive : any;
  DeletingGeofenceName : any;
  GeofenceDeletedError = false;
  GeofenceDeleted = false;
  DeletingGeofence = true;
  NotDrawnError = false;
  IncompleteForm = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private DS: DevicesService, private BS: BroadcastService, private fb: FormBuilder, private LS : LoggedInService) { }

  ngOnInit(): void {
    this.mapProp = {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: 6,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.AddGeofenceForm = this.fb.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      polygon_color : ['', Validators.required],
      polygon : ['', Validators.required],
      center : [''],
      radius : [''],
      user_api_hash : [''],
      lang : ['']
    });

    this.checkLoggedIn();
  }

  ngAfterContentInit() {
    //initialise map after slight delay for dom to be ready
    setTimeout(() => {
      this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);
      this.initMap();
    }, 30);
  }

  showGeofenceList() {
    this.drawingManager.setDrawingMode(null);
    this.getGeofences();
    this.ListActive = false;
  }
  
  showAddGeofence() {
    this.AddError = false;
    this.addNewMap();
    this.ListActive = true;
  }

  checkLoggedIn() {
    if (this.LS.checkStatus() != 0) {
      this.hash = this.LS.checkStatus();
      this.AddGeofenceForm.patchValue({lang : 'en', user_api_hash : this.hash});
      this.getGeofences();
    } else {
      this.router.navigate(['/']);
    }
  }

  submitGeofence() {
    if (this.PolygonActive == true) { //if a polygon was drawn
      if (this.PolygonValue != undefined) {
        let path = this.PolygonValue.overlay.getPath().getArray();
        this.AddGeofenceForm.patchValue({polygon : path});
        this.AddGeofenceForm.patchValue({type : 'polygon'});

        //reset values for circle
        this.AddGeofenceForm.patchValue({radius: ''});
        this.AddGeofenceForm.patchValue({center: ''});
      } else {
        this.NotDrawnError = true;
        return;
      }
    } else {
      return;
      // this.AddGeofenceForm.patchValue({radius: this.PolygonValue.getRadius()});
      // let center = {lat : this.PolygonValue.getCenter().lat(), lng: this.PolygonValue.getCenter().lng()};
      // this.AddGeofenceForm.patchValue({center: center});
      // this.AddGeofenceForm.patchValue({polygon: [center]});
    }
    if (this.AddGeofenceForm.invalid) {
      this.IncompleteForm = true;
      return;
    }
    this.LoadingSubmit = true;

    var form_str = this.constructString();

    this.IncompleteForm = false;
    this.AddSuccess = false;
    this.AddError = false;
    this.NotDrawnError = false;
    var self = this;
    
    this.DS.addGeofence(form_str).subscribe( data => {
      this.LoadingSubmit = false;
      // console.log('response');
      // console.log(data);
      if (data.status == 1) {
        this.AddSuccess = true;
        this.AddError = false;
        this.AddGeofenceForm.reset();
        this.getGeofences();
        setTimeout(function() {
          self.clearMap();
          self.showGeofenceList();
          this.AddSuccess = false;
        }, 2000);
      } else {
        this.AddError = true;
      }
    }, error => {
      this.LoadingSubmit = false;
      // console.log('error');
      console.log(error);     
      this.AddError = true; 
    });
  }

  constructString() {
    let form_data = this.AddGeofenceForm.value;
    let polygon = this.formatPolygon()
    let polygon_color = this.AddGeofenceForm.value.polygon_color.replace("#", "%20");
    let request_string = 'user_api_hash='+form_data.user_api_hash+'&lang=en'+'&name='+form_data.name+'&polygon_color='+polygon_color+'&type='+form_data.type+'&polygon='+polygon;
    //+'&center='+form_data.center+'&radius='+form_data.radius;
    // console.log(request_string);
    return request_string;    
  }

  formatPolygon() {
    let polygon = this.AddGeofenceForm.value.polygon;
    let polygonarray = [];
    let first;
    for (let i = 0; i < polygon.length; i++) {
      let lat = polygon[i].lat();
      let lng = polygon[i].lng();
      let coordinates = {lat : lat, lng : lng};
      polygonarray.push(coordinates);

      if (i == 0) { //store first coordinates
        first = coordinates;
      }
      if (i == (polygon.length - 1)) { //push first coordinates to end of array
        polygonarray.push(first);
      }
    }
    return JSON.stringify(polygonarray);
  }

  initMap () {
    this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);
  }

  updateShapeType () {
    if(this.PolygonActive == true) {//if drawing mode is poygon
      if (this.AddGeofenceForm.value.type == 'Circle') {
        this.PolygonActive = false;
        this.initMap();
        this.addControls('circle');
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
      }
    } else {
      this.PolygonActive = true;
      if(this.drawingManager.getMap()) {
        this.drawingManager.setMap(null); //Used to disable drawing tool
      }
      this.initMap();
      this.addControls('polygon');
      this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    }
  }

  updateColor() {
      if (this.PolygonActive == true ){
        if (this.ShapeDrawn.length > 0) {
          //change current shape color and stroke color
          this.ShapeDrawn[0].overlay.setOptions({fillColor: this.AddGeofenceForm.value.polygon_color, strokeColor: this.AddGeofenceForm.value.polygon_color});  
        }

        //change drawing tool background and stroke color
        var polygonOptions = this.drawingManager.get('polygonOptions');
        polygonOptions.fillColor = this.AddGeofenceForm.value.polygon_color; 
        polygonOptions.strokeColor = this.AddGeofenceForm.value.polygon_color; 
      }
      else {
        if (this.ShapeDrawn.length > 0) {
          //change current shape color and stroke color
          this.ShapeDrawn[0].setOptions({fillColor: this.AddGeofenceForm.value.polygon_color, strokeColor: this.AddGeofenceForm.value.polygon_color});
        }

        //change drawing tool background and stroke color
        var polygonOptions = this.drawingManager.get('circleOptions');
        polygonOptions.fillColor = this.AddGeofenceForm.value.polygon_color; 
        polygonOptions.strokeColor = this.AddGeofenceForm.value.polygon_color; 
      }
    this.drawingManager.set('polygonOptions', polygonOptions);
  }

  clearMap() {
    this.drawingManager.setDrawingMode(null);
    if (this.AddGeofenceForm.value.type == 'Circle') {
      this.ShapeDrawn[0].setMap(null);
      this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
    } else {
      if (this.ShapeDrawn[0]) {
        this.ShapeDrawn[0].overlay.setMap(null);
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
      }
    }  
    this.PolygonValue = {};
    this.ShapeDrawn = [];
  }

  addControls(type) {
    if (type == 'circle') { //add circle controls
      var polyOptions = {
        editable: true,
        fillColor: this.AddGeofenceForm.value.polygon_color,
        strokeColor: this.AddGeofenceForm.value.polygon_color
      };
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.CIRCLE,
        drawingControl: false,
        circleOptions: polyOptions,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.CIRCLE
            // google.maps.drawing.OverlayType.RECTANGLE
          ]
        }
      });

      google.maps.event.addListener(this.drawingManager, 'circlecomplete', (event) => { //if circle is drawn
        // event.setMap(null);
        // this.drawingManager.setDrawingMode(null);
        
        // alert('aa');
        //   alert(event.getCenter());
          // alert(event.getRadius());
          this.PolygonValue = event;
          this.ShapeDrawn = []; //reset shapes object 
          this.ShapeDrawn.push(event); //add new shape to array
          this.drawingManager.setDrawingMode(null); //Disallow more shapes
        });

    } else if (type == 'polygon') { //add polygon controls
      var polyOptions = {
        editable: true,
        fillColor: this.AddGeofenceForm.value.polygon_color,
        strokeColor: this.AddGeofenceForm.value.polygon_color
      };
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON, 
        markerOptions: {
          draggable: true
        },
        polylineOptions: {
          editable: true
        },
        polygonOptions: polyOptions,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
            // google.maps.drawing.OverlayType.RECTANGLE
          ]
        }
      });

      google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
        // Polygon drawn
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          //this is the coordinate, you can assign it to a variable or pass into another function.
          this.PolygonValue = event;
          this.NotDrawnError = false;
          
          // google.maps.event.addListener(event.overlay.getPath(), 'dragend', function(event){
          //     alert('t');
          //   // Polygon was dragged
          //   if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          //     //this is the coordinate, you can assign it to a variable or pass into another function.
          //     this.PolygonValue = event;
          //     // this.PolygonValue = event.overlay.getPath().getArray();
          //     alert('t');
          //   }

          //   this.ShapeDrawn = [];
          //   this.ShapeDrawn.push(event);
          //   this.drawingManager.setDrawingMode(null); //switch back to non drawing mode
          // });
          // this.PolygonValue = event.overlay.getPath().getArray();
        }
        this.ShapeDrawn = [];
        this.ShapeDrawn.push(event);
        this.drawingManager.setDrawingMode(null); //switch back to non drawing mode
      });

    }
    this.drawingManager.setMap(this.map);
  }

  getGeofences() {
    this.DS.getGeofences(this.hash).subscribe(data => {
      this.Geofences = data.items.geofences;
      this.drawGeofences(this.Geofences);
    });
  }

  drawGeofences(fences) {
    this.initMap();
    this.DrawnPolygons = [];
    for(let i = 0; i < fences.length; i++) {
      let color = this.fixColorCode(fences[i]['polygon_color']);

      // Construct the polygon.
      const newpolygon = new google.maps.Polygon({
        paths: JSON.parse(fences[i]['coordinates']),
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.35,
      });
      // console.log(fences[i]['polygon_color']);
      this.DrawnPolygons.push(newpolygon);
      newpolygon.setMap(this.map);      
    }
  }

  highlightOnMap(i) {
    this.resetPolygonColor(this.Geofences[i]);
    
    this.ClickedPolygon = this.DrawnPolygons[i];
    // console.log(this.DrawnPolygons[i]);
    //change color of clicked polygon 
    this.DrawnPolygons[i].setOptions({fillColor: '#0896D4', strokeColor: '#2B8C0C'});
    
    var coordinates = JSON.parse(this.Geofences[i].coordinates);
    let center = this.getCenterOfPolygon(this.DrawnPolygons[i]);
    
    //Highlight the geofence on the map
    this.map.setOptions({
      center: new google.maps.LatLng(center.lat(), center.lng()),
      zoom: 8
    });
  }

  setActiveGeofence(i) {
    this.GeofenceItemActive = i;
  }

  showDeleteGeofence(i) {
    // if (this.GeofenceItemActive == i) {
      this.DeletingGeofenceName = this.Geofences[i].name;
      this.DeletingGeofence = true;
      $('#confirm-delete-modal').fadeIn('slow');
    // }
  }

  confirmGeofenceDelete(i) {
    this.DS.deleteGeofence(this.hash,i).subscribe( data=>{
      // console.log(data);
      if (data.status == 1) {
        this.DeletingGeofence = false;
        this.GeofenceDeleted = true;
        this.getGeofences();
      } else {
        this.GeofenceDeletedError = true;
      }
      let self = this;
      setTimeout(function() {
        self.GeofenceDeleted = false;
        self.GeofenceDeletedError = false;
        self.hideDeletePrompt();
      }, 2000);
    }, error=> {
      // console.log(error);      
      let self = this;
      setTimeout(function() {
        self.GeofenceDeleted = false;
        self.GeofenceDeletedError = false;
        self.hideDeletePrompt();
      }, 2000);
    });
  }

  hideDeletePrompt() {
    $('#confirm-delete-modal').fadeOut('fast', function(){
      this.DeletingGeofence = true;
    });
  }

  resetPolygonColor (fence) {
    if (this.ClickedPolygon) { //reset previous clicked polygon to default colors
      let color = this.fixColorCode(fence.polygon_color);
      this.ClickedPolygon.setOptions({fillColor: color , strokeColor: color });
    }
    // console.log(this.ClickedPolygon);   
  }

  fixColorCode (color) {
    // let color = fence['polygon_color'];
      color = color.trim();
      if (!color.includes("#")) {
        color = '#'+color;
      }
      return color;
  }


  getCenterOfPolygon(polygon){
    var PI=22/7
    var X=0;
    var Y=0;
    var Z=0;
    polygon.getPath().forEach(function (vertex, inex) {
      let lat1=vertex.lat();
      let lon1=vertex.lng();
      lat1 = lat1 * PI/180
      lon1 = lon1 * PI/180
      X += Math.cos(lat1) * Math.cos(lon1)
      Y += Math.cos(lat1) * Math.sin(lon1)
      Z += Math.sin(lat1)
    })
    let Lon = Math.atan2(Y, X)
    let Hyp = Math.sqrt(X * X + Y * Y)
    let Lat = Math.atan2(Z, Hyp)
    Lat = Lat * 180/PI
    Lon = Lon * 180/PI 
    return new google.maps.LatLng(Lat,Lon);
  }

  addNewMap() {
    this.initMap();
    this.addControls('polygon');
  }
}