import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as $ from 'jquery';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'WeTrackFrontend';
  RouteSwitchCounter = 0;

  constructor(private router: Router) { };

  ngOnInit() {
    AOS.init();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let self = this;

        if (this.RouteSwitchCounter != 0 && $('html').hasClass('nav-open')) {
          setTimeout(function () {
            document.getElementById('navbar-toggler').click();
          }, 700);
        }
        self.RouteSwitchCounter = self.RouteSwitchCounter + 1;
      });
  }

  ngAfterViewInit() {
    window.addEventListener('load', AOS.refresh)
  }
}
