import { Component, ViewChild, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { BroadcastService } from '../_services/broadcast.service';

declare const WebViewer: any;

@Component({
  selector: 'web-viewer',
  templateUrl: './webviewer.component.html',
  styleUrls: ['./webviewer.component.css']
})
export class WebviewerComponent implements OnInit, AfterViewInit {

  // Syntax if using Angular 8+
  // true or false depending on code
  @ViewChild('viewer', {static:false}) viewer: ElementRef;

  wvInstance: any;
  pdf: any;

  constructor(private BS: BroadcastService) {
    let self = this;
    this.BS.changeCurrentPdf.subscribe(data => {
      if(!this.pdf) {
        self.pdf = data.pdf;
          self.loadPdfTron(data.pdf); 
      } else {
        self.pdf = data.pdf;
        self.wvInstance.loadDocument(self.pdf);
      }
    });

    this.BS.closePdfViewer.subscribe(data => {
      self.closeCurrentPdf();
    });
  }

  ngOnInit() {
  }


  ngAfterViewInit(): void {
    // The following code initiates a new instance of WebViewer.
  }

  closeCurrentPdf () {
    // WebViewer().then(function(instance) {
      // var docViewer = instance.docViewer;
  
      // // you must have a document loaded when calling this api
      // docViewer.on('documentLoaded', function() {
      //   setTimeout(function() {
          var self = this;
          this.wvInstance.closeDocument().then(function() {
            self.wvInstance.dispose();
          });
          
          // window.location.reload();
          // console.log(this.wvInstance);
          
    //     }, 3000);
    //   });
    // });
  }

  loadPdfTron (pdf) {
    // console.log(this.pdf);
    
    WebViewer({
      path: '../../wv-resources/lib',
      // initialDoc: 'https://pdftron.s3.amazonaws.com/downloads/pl/webviewer-demo.pdf',
      initialDoc: pdf,
      documentType: 'pdf',
      fullAPI: true, 
    }, this.viewer.nativeElement).then(instance => {
      this.wvInstance = instance;
      let self = this;
        this.BS.pdfLoaded.emit('yes');

      instance.docViewer.on('documentLoaded', async () => {
        // const doc = instance.docViewer.getDocument();
        // const pdfDoc = await doc.getPDFDoc();

          // Ensure that we have our first page.
        // await pdfDoc.requirePage(1);

        // Run our main function using 'runWithCleanup'
        // await PDFNet.runWithCleanup(async () => await main(pdfDoc));

                // Refresh the cache with the newly updated document
        // instance.docViewer.refreshAll();
        // Update viewer with new document
        // instance.docViewer.updateView();
          // self.wvInstance.zoomTo(90);        
    });
  });
  }
}