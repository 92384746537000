import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoggedInService } from '../../_services/logged-in-service.service';
import { config } from '../../_helpers/global';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-form',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  LoginForm: FormGroup;
  loading = false;
  unregisterederror = false;
  loginerror = false;
  loggedin = false;
  admin = false;
  submitted = false;
  accesslevel: any;
  username: any;
  checkStatus: any;
  error_message: any;
  unknownerror = false;

  constructor(private loggedInService: LoggedInService, private fb: FormBuilder, private router: Router) {
    this.checkStatus = this.loggedInService.checkStatus();
    // this.loggedin = this.loggedInService.loggedin;
    this.admin = this.loggedInService.admin;
    this.accesslevel = this.loggedInService.accesslevel;
    this.username = this.loggedInService.username;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.LoginForm = this.fb.group({
      email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      password: ['', Validators.required]
    });
  }

  login() {
    this.submitted = true;
    if (this.LoginForm.invalid) {
      this.loggedin = false;
      return;
    }
    this.loading = true;
    var self = this;
    var form_str = this.constructString();
    // console.log(form_str);
    // this.loggedInService.logIn(form_str);
    this.loggedInService.logIn(form_str).subscribe(data => {
      this.loading = false;
      this.submitted = false;
      this.handleResponse(data);
      // console.log(data);
    }, error => {
      console.log('error');
      // console.log(error);
      this.loading = false;
      this.handleResponse(error);
    });
  }

  constructString() {
    let form_data = this.LoginForm.value;
    // return (JSON.stringify(form_data));
    return form_data
  }

  handleResponse(response) {
    if (response.status == 401) {
      this.unknownerror = false;
      this.loginerror = true;
      this.error_message = response.error.message;
      console.log(this.error_message);
    } else if (response.status == 1) {
      this.unknownerror = false;
      this.loggedin = true;
      const now = new Date();
      const wetrack_user = {
        user: response
        // expiry: now.getTime() + 86400000 //add one day to current time
      }
      localStorage.setItem('wetrack_user', JSON.stringify(wetrack_user));
      this.router.navigate(['/home']);
    } else {
      this.unknownerror = true;
      console.log('something wrong');
    }
  }

  toggleRegister() {
    $('#id01').fadeOut();
    $('#id02').fadeIn();
  }

  toggleLogin() {
    $('#id02').css({ 'display': 'none' });
    $('#id01').css({ 'display': 'block' });
  }
}
