import { config } from '../_helpers/global';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class LoggedInService {
  loggedin = false;
  admin = false;
  dealer = false;
  accesslevel = 0;
  username: any;
  userId: any;
  user_api_hash: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  checkStatus() {
    var user = JSON.parse(localStorage.getItem('wetrack_user'));
    if (!user) {
      return 0; //no user
    } else {
      this.loggedin = true;
      this.user_api_hash = user.user.user_api_hash;
      return this.user_api_hash;
      // const now = new Date();
      // if (now.getTime() > user.expiry) {
      //   return null;
      // } else {
      // console.log(user.user);
      // if (user.user.access_level == 1) { //if user is admin
      //   this.accesslevel = 1;
      //   this.admin = true;
      //  console.log(this.accesslevel);
      // } else if (user.user.access_level == 2) { //if user is a dealer
      //   this.accesslevel = 2;
      //   this.dealer = true;
      // } else {
      //   this.accesslevel = 0;
      // }
      // }
    }
  }

  logIn(request_string) {
    // console.log('service');

    return this.http.post<any>(`${config.apiUrl}/login`, request_string, this.httpOptions);
    // var settings = {
    //   "url": "https://assets.wetrack.co.ke/api/login",
    //   "method": "POST",
    //   "timeout": 0,
    //   "headers": {
    //     "Content-Type": "application/json",
    //     "tkn": "123"
    //   },
    //   "data": JSON.stringify({ "email": "admin@wetrack.co.ke", "password": "-Wqpe~YtZLJ8" }),
    // };

    // $.ajax(settings).done(function (response) {
    //   console.log(response);
    // });
  }

  logOut() {
    localStorage.removeItem('wetrack_user');
    this.loggedin = false;
    window.location.href = '';
  }

}