<div class="wrapper ">
    <!--Confirm Delete Gefence-->
    <div class="confirm-delete-modal" id="confirm-delete-modal" style="display: none;">
        <div class="confirm-delete-inner" *ngIf="DeletingGeofence">
            <div class="row">
                <h4>Are you sure you want to delete the "{{DeletingGeofenceName}}" Geofence?</h4>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <button class="btn btn-success" (click)="confirmGeofenceDelete(Geofences[GeofenceItemActive].id)">Yes</button>
                </div>
                <div class="col-md-6">
                    <button (click)="hideDeletePrompt()" class="btn pull-right btn-danger">No</button>
                </div>
            </div>
        </div>
        <div *ngIf="GeofenceDeleted" class="confirm-delete-inner">
            <div class="row">
                <h4>
                    Geofence was successfully deleted.
                </h4>
            </div>
        </div>
        <div class="confirm-delete-inner" *ngIf="GeofenceDeletedError">
            <div class="row">
                <h4 class="text-red">
                    Something went wrong. Please try again.
                </h4>
            </div>
        </div>
    </div>
    <!--Confirm Delete Gefence end-->
    
    <!--sidebar-->
        <app-side-bar></app-side-bar>
    <!--sidebar End-->

    <div class="main-panel">
        <div class="row main-geofences-wrapper">
            <div class="goefence-chooser col-md-3">
                <div class="geofence-chooser-header">
                    <h2>Goefences</h2>
                </div>
                <button *ngIf="!ListActive" class="add-geofence btn btn-success" id="show-add-geofence" (click)="showAddGeofence()">Add New</button>
                <button class="add-geofence" *ngIf="ListActive" (click)="showGeofenceList()" id="show-geofence-list">Back</button>
                <div class="add-geofence-form-wrapper p-3" [class]="{'d-none': !ListActive}">
                    <div class="mb-5">
                        <div class="alert alert-success">Draw a shape on the map then fill the form below</div>
                    </div>
                    <form [formGroup]="AddGeofenceForm">
                        <div class="form-group">
                            <label for="geofence_name">Name</label>
                            <input type="text" class="form-control" formControlName="name" name="name" id="geofence_name">
                        </div>
                        <!-- <div class="form-group">
                            <label for="geofence_type">Type</label>
                            <select  (change)="updateShapeType()" formControlName="type" type="text" class="form-control" name="type" id="geofence_type">
                                <option value="polygon" selected="selected">Polygon</option>
                                <option value="circle">Circle</option>
                            </select>
                        </div> -->
                        <div class="form-group">
                            <label for="geofence_color" class="mb-3">Background Color</label>
                            <input type="color" name="polygon_color" value="#ffffff" (change)="updateColor()" formControlName="polygon_color" id="geofence_color" class="form-control">
                        </div>
                        <div class="form-group">
                            <input *ngIf="!LoadingSubmit" type="submit"  name="submit_geofence" id="submit_geofence" value="Save" (click)="submitGeofence()">
                            <input *ngIf="LoadingSubmit" type="submit"  name="submit_geofence" id="submit_geofence" value="Loading..." >
                        </div>
                        <div *ngIf="AddSuccess" class="alert alert-success">
                            <p>Your geofence was successfully added</p>
                        </div>
                        <div *ngIf="AddError" class="alert alert-danger">
                            <p id="add-error">Something went wrong please try again.</p>
                        </div>
                        <div *ngIf="NotDrawnError" class="alert alert-danger">
                            <p id="add-error">Draw something on the Map</p>
                        </div>
                        <div *ngIf="IncompleteForm" class="alert alert-danger">
                            <p id="add-error">Please complete the form above</p>
                        </div>
                    </form>
                </div>
                <div [class]="{'d-none': ListActive}" class="geofence-list-wrapper p-3">
                    <div *ngFor="let geofence of Geofences; let i = index" (click)="highlightOnMap(i)" (click)="setActiveGeofence(i)" class="geofence-list cursor-pointer" id="geofence_{{i}}">
                        <div  [class]="{'geofence-item-active' : GeofenceItemActive == i}" class="geofence-list-inner border-right">
                            <h4><b class="pr-2">{{i+1}}.</b></h4>
                            <h4 >{{geofence.name}}</h4>
                        </div>
                        <div class=" delete-geofence-btn-wrapper" (click)="showDeleteGeofence(i)">
                            <i class="far fa-trash-alt delete-geofence-btn" [class]="{'delete-geofence-btn-active' : GeofenceItemActive == i}" ></i>
                        </div>
                    </div>
                    <!-- <div class="geofence-list">
                        <h4>Mombasa</h4>
                    </div> -->
                </div>
            </div>
            <div class="map-wrapper-geofences col-md-9">
                    <div #gmap style="width:100%;height:100%"></div>
                    <button class="clear-map" *ngIf="ListActive" (click)="clearMap()">Clear Selection</button>
            </div>
        </div>
        <!--footer-->
        <app-footer></app-footer>
        <!--footer END-->
    </div>
</div>