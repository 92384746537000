import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactServiceService } from 'src/app/_services/contact-service.service';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})

export class ContactFormComponent implements OnInit {
  ContactForm: FormGroup;
  ReportProblemForm: FormGroup;
  submitted = false;
  loading = false;
  submiterror = false;
  success = false;

  constructor(private fb: FormBuilder, private CS: ContactServiceService) { }

  ngOnInit(): void {
    this.ContactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      message: ['', Validators.required]
    });
    this.ReportProblemForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      message: ['', Validators.required]
    });
  }

  toggleRegister() {
    $('#id01').fadeOut();
    $('#id02').fadeIn();
  }

  toggleLogin() {
    $('#id02').css({ 'display': 'none' });
    $('#id01').css({ 'display': 'block' });
  }

  onSubmit() {
    this.submitted = true;
    if (this.ContactForm.invalid) {
      return;
    } else {
      this.loading = true;
      var formdata = this.ContactForm.value;
      this.CS.contactUs(formdata).subscribe(data => {
        data = JSON.parse(data);
        this.loading = false;
        this.submitted = false;
        let error_code = parseInt(data[1], 10);
        if (error_code == 200) {
          this.ContactForm.reset();
          this.success = true;
          setTimeout(function () {
            this.success = false;
          }, 5000)
          this.submiterror = false;
        } else {
          this.loading = false;
          this.success = false;
          this.submiterror = true;
        }
      }, error => {
        this.loading = false;
        console.log(error);
        this.submiterror = true;
      })
    }
  }

  onSubmit2() {
    this.submitted = true;
    if (this.ReportProblemForm.invalid) {
      return;
    } else {
      this.loading = true;
      var formdata = this.ReportProblemForm.value;
      this.CS.reportProblem(formdata).subscribe(data => {
        data = JSON.parse(data);
        this.loading = false;
        this.submitted = false;
        let error_code = parseInt(data[1], 10);
        if (error_code == 200) {
          this.ReportProblemForm.reset();
          this.success = true;
          setTimeout(function () {
            this.success = false;
          }, 5000)
          this.submiterror = false;
        } else {
          this.loading = false;
          this.success = false;
          this.submiterror = true;
        }
      }, error => {
        this.loading = false;
        console.log(error);
        this.submiterror = true;
      })
    }
  }

}