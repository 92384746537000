import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoggedInService } from 'src/app/_services/logged-in-service.service';
import { DevicesService } from 'src/app/_services/devices.service';

@Component({
  selector: 'app-history-sidebar',
  templateUrl: './history-sidebar.component.html',
  styleUrls: ['./history-sidebar.component.css']
})

export class HistorySidebarComponent implements OnInit {
  NumberPlate: any;
  UserApiHash: any;
  HistoryForm: FormGroup;
  loading = false;
  submitted = false;
  radiocheck = true;
  loadinghistory = false;
  historyloaded = false;
  DeviceId: any;

  constructor(private BS: BroadcastService, private fb: FormBuilder, private LS: LoggedInService, private DS: DevicesService) {
    this.BS.toggleHistorySideBar.subscribe(data => {
      if (data.device) {
        this.NumberPlate = data.device.name;
        this.DeviceId = data.device.id;
        this.checkLoggedIn();
      }
      $('#history-sidebar-right').addClass('active');
      $('#reports-background-modal').show().addClass('active');
    });
  }

  ngOnInit(): void {
    this.HistoryForm = this.fb.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      to_time: ['', Validators.required],
      from_time: ['', Validators.required],
      device_id: ['', Validators.required],
      user_api_hash: ['', Validators.required],
      lang: ['', Validators.required],
      stops: [''],
      alerts: ['']
    });
    var self = this;
    this.setDefaultValues();
    //toggle date on demand
    $('body').on('click', '.history-date-button', function (event) {
      let thisone = this;
      self.toggleDate(event, thisone);
    });

    //hide sidebar on button click 
    $('body').on('click', '#close-history-side', function () {
      $('#reports-background-modal').click();
    });

    //hide sidebar on button click 
    $('body').on('click', '#close-history-side2', function (event) {
      $('#reports-background-modal').click();
    });

    //hide sidebar on click away
    $('body').on('click', '#reports-background-modal', function (event) {
      self.hideSideBar(event);
      setTimeout(function () {
        self.resetSideBar();
      }, 450);
    });
  }

  hideSideBar(event) {
    if (!$(event.target).closest('#history-sidebar-right').length && !$(event.target).is('#history-sidebar-right')) {
      $('#history-sidebar-right').removeClass('active').removeClass('map-active');
      $('#reports-background-modal').removeClass('active');
      setTimeout(function () {
        $('#reports-background-modal').hide();
      }, 450);
    }
    this.BS.resetMap.emit('yes');
  }

  checkLoggedIn() {
    if (this.LS.checkStatus() != 0) {
      this.UserApiHash = this.LS.checkStatus();
    }
    this.setDefaultValues();
  }

  toggleDate(event, thisone) {
    if (event.target.id == "history-custom-date") {
      $('#history-date-selector').removeClass('d-none');
    } else {
      $('#history-date-selector').addClass('d-none');
    }
    for (let i = 0; i < $('.history-date-button').length; i++) {
      let btnid = $('.history-date-button')[i].id;
      $('#' + btnid).removeClass('date-active');
    }
    this.replaceDate(event.target.id);
    $(thisone).addClass('date-active');
  }

  replaceDate(id) {
    var today = new Date();
    if (id == "history-custom-date-24") {
      //Today till current time
      this.HistoryForm.patchValue({ from_date: this.formatDate(new Date(Date.now())) });
      this.HistoryForm.patchValue({ from_time: '00:00' });
      this.HistoryForm.patchValue({ to_date: this.formatDate(new Date(Date.now())) });
      this.HistoryForm.patchValue({ to_time: today.getHours() + ':00' });
    } else if (id == "history-custom-date-1") {
      //1 hour earlier
      this.HistoryForm.patchValue({ from_date: this.formatDate(new Date(Date.now())) });
      let lasthour = String(today.getHours() - 1) + ':00';
      this.HistoryForm.patchValue({ from_time: lasthour });
      this.HistoryForm.patchValue({ to_date: this.formatDate(new Date(Date.now())) });
      this.HistoryForm.patchValue({ to_time: today.getHours() + ':00' });
    } else if (id == "history-custom-date-yesterday") {
      //Yesterday
      this.HistoryForm.patchValue({ from_date: this.formatDate(new Date(Date.now() - 864e5)) });
      this.HistoryForm.patchValue({ from_time: '00:00' });
      this.HistoryForm.patchValue({ to_date: this.formatDate(new Date(Date.now() - 864e5)) });
      this.HistoryForm.patchValue({ to_time: '23:59' });
    }
  }

  resetSideBar() {
    this.ngOnInit();
    this.loading = false;
    this.submitted = false;
    // this.loadingreport = false;
    // this.reportloaded = false;
    this.radiocheck = true;
    $('#myassets-wrapper').removeClass('no-scrollbars');
    $('#sidemap-options').show();
    $('#sidemap-wrapper').hide();
    $('#no-data-error').fadeOut();
    if (!$('#history-custom-date-24').hasClass('date-active')) {
      $('#history-custom-date-24').click(); //reset date btn
    }
    // this.header = '';
    // this.tagline = '';
    // this.devices ;
    // this.cars = [];
    // this.carID = '';
    // this.anchor_tag = "";
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  setDefaultValues() {
    var today = new Date();
    let lasthour = '00:00';

    this.HistoryForm.patchValue({
      lang: 'en',
      user_api_hash: this.UserApiHash,
      device_id: this.DeviceId,
      to_date: this.formatDate(new Date(Date.now())),
      from_date: this.formatDate(new Date(Date.now())),
      to_time: today.getHours() + ':00',
      from_time: lasthour,
      stops: 0,
      alerts: 1
    });
  }

  getHistory() {
    this.submitted = true;
    $('#no-data-error').fadeOut();
    this.checkValidity();
    let api_call_string = this.constructString();
    this.loadinghistory = true;
    console.log(api_call_string);
    this.DS.getHistory(api_call_string, this.UserApiHash).subscribe(data => {
      this.loadinghistory = false;
      if (data.status == 3) {
        this.historyloaded = true;
      }
      // console.log(data);
      if (data.items.length > 0) {
        this.BS.toggleMapSidebar.emit({ history: data, form: this.HistoryForm.value });
        $('#sidemap-options').fadeOut();
        $('#sidemap-wrapper').fadeIn();
        $('#myassets-wrapper').addClass('no-scrollbars');
        $('#history-sidebar-right').removeClass('active').addClass('map-active');
      } else {
        $('#no-data-error').fadeIn();
      }
    }, error => {
      this.loadinghistory = false;
      console.log('error');
      console.log(error);
    });
  }

  constructString() {
    let variables_string = JSON.stringify(this.HistoryForm.value);
    return variables_string;
  }

  checkValidity() {

  }
}