<div class="wrapper ">
    <!--sidebar-->
    <app-side-bar></app-side-bar>
    <!--sidebar End-->


    <!-- reports sidebar -->
        <div class="reports-background-modal" id="reports-background-modal" style="display: none;"></div>
        <app-reports-side-bar></app-reports-side-bar>
    <!-- reports sidebar end-->


    <div class="main-panel">
      <!-- Navbar -->
        <!-- <app-nav-bar></app-nav-bar> -->
      <!-- End Navbar -->
      
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div (click)="toggleSidebar('General Report', 'A Broad General Report', 1)" class="cursor-pointer toggle-reports card card-stats">
                <div class="card-header card-header-warning card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">help_center</i>
                  </div>
                  <p class="card-category"></p>
                  <h3 class="card-title text-center">
                      General Info
                  </h3>
                </div>
                <div class="card-footer">
                  <div class="stats">
                    <!-- <i class="material-icons text-danger">warning</i> -->
                    A Broad General Report
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 toggle-reports col-md-6 col-sm-6">
              <div class="card card-stats cursor-pointer" (click)="toggleSidebar('Drives & Stops', 'A report on routes taken and stops.', 3)">
                <div class="card-header card-header-success card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">store</i>
                  </div>
                  <h3 class="text-center card-title">Drives & Stops</h3>
                </div>
                <div class="card-footer">
                  <div class="stats">
                    <!-- <i class="material-icons">date_range</i> -->
                    A report on routes taken and stops.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 toggle-reports col-md-6 col-sm-6">
              <div class="card cursor-pointer card-stats" (click)="toggleSidebar('Overspeed', 'A report on all the Overspeed events', 5)">
                <div class="card-header card-header-danger card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">info_outline</i>
                  </div>
                  <h3 class="card-title">Overspeed</h3>
                </div>
                <div class="card-footer">
                  <div class="stats">
                    A report on all the Overspeed events
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 toggle-reports col-md-6 col-sm-6">
              <div class="cursor-pointer card card-stats" (click)="toggleSidebar('Travel Sheet', 'A Route Summary Of Your Vehicles', 4)">
                <div class="card-header card-header-info card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">assignment</i>
                  </div>
                  <h3 class="card-title">Travel Sheet</h3>
                </div>
                <div class="card-footer">
                  <div class="stats">
                      A Route Summary Of Your Vehicles
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 toggle-reports col-md-6 col-sm-6">
              <div class="cursor-pointer card card-stats" (click)="toggleSidebar('Offline Devices', 'A summary of your offline devices', 38)">
                <div class="card-header card-header-info card-header-icon">
                  <div class="card-icon" style="background: rgb(179, 179, 4);">
                    <i class="material-icons">gps_off</i>
                  </div>
                  <h3 class="card-title">Offline Devices</h3>
                </div>
                <div class="card-footer">
                  <div class="stats">
                    A summary of your offline devices
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--footer-->
        <app-footer></app-footer>
      <!--footer END-->
    </div>
  </div>