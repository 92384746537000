import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoggedInService } from '../_services/logged-in-service.service';
import { error } from 'protractor';
import { Router } from '@angular/router';
import {
  bounceAnimation,
  bounceInRightAnimation,
  bounceInRightOnEnterAnimation,
  bounceInUpOnEnterAnimation,
  fadeInExpandRightOnEnterAnimation,
  fadeInRightOnEnterAnimation,
  fadeInUpOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  fadeOutRightOnLeaveAnimation,
  fadeOutUpOnLeaveAnimation,
  pulseAnimation
} from 'angular-animations';
import { delay } from 'rxjs/operators';

import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  animations: [
    fadeInUpOnEnterAnimation({ anchor: 'enter1', delay: 100 }),
    fadeInUpOnEnterAnimation({ anchor: 'enter2', delay: 140 }),
    fadeInUpOnEnterAnimation({ anchor: 'enter3', delay: 180 }),
    fadeOutUpOnLeaveAnimation({ anchor: 'leave1' }),
    fadeOutUpOnLeaveAnimation({ anchor: 'leave2' }),
    fadeOutUpOnLeaveAnimation({ anchor: 'leave3' }),
    fadeInRightOnEnterAnimation({ anchor: 'faderight' }),
    fadeOutRightOnLeaveAnimation({ anchor: 'leaveright', delay: 70 }),
    bounceInRightOnEnterAnimation({ anchor: 'bounceright', delay: 100 }),
    pulseAnimation({ duration: 1500, delay: 180 }),
    bounceAnimation({ duration: 2000 }),
  ]
})

export class LandingPageComponent implements OnInit {
  LoginForm: FormGroup;
  loading = false;
  unknownerror = false;
  loginerror = false;
  loggedin = false;
  admin = false;
  submitted = false;
  accesslevel: any;
  username: any;
  checkStatus: any;
  error_message: any;
  animationState = false;

  constructor(private loggedInService: LoggedInService, private fb: FormBuilder, private router: Router, private deviceService: DeviceDetectorService) {
    this.checkStatus = this.loggedInService.checkStatus();
    // this.loggedin = this.loggedInService.loggedin;
    this.admin = this.loggedInService.admin;
    this.accesslevel = this.loggedInService.accesslevel;
    this.username = this.loggedInService.username;
  }

  ngOnInit(): void {

    this.checkLoggedIn();
    this.createForm();
    //show header on scroll up

    $(document).ready(function () {
      var previousScroll = 0,
        headerOrgOffset = $('#header').offset().top;

      $('#mainNav').height($('#header').height());

      // $(document.body).on('touchmove', onScroll); // for mobile
      $(window).on('scroll', onScroll);

      function onScroll() {
        var currentScroll = $(this).scrollTop();
        // console.log(currentScroll + " and " + previousScroll + " and " + headerOrgOffset);
        if (currentScroll > headerOrgOffset) {
          if (currentScroll > previousScroll) {
            $('#header').fadeOut('fast');
          } else {
            $('#header').fadeIn('fast');
            $('#header').addClass('fixed');
          }
        } else {
          $('#header').removeClass('fixed');
          $('#header').fadeIn('fast');
        }
        previousScroll = currentScroll;
      }
      // $(window).scroll( function() {

      // });
    });
  }

  pulseDone() {
    this.animationState = !this.animationState;
  }

  whatsappRedirect() {
    let phone_number = "254717089348";
    let currenturl = encodeURI(window.location.href);
    var str;
    let deviceInfo = this.deviceService.getDeviceInfo();


    if (this.deviceService.isMobile() || this.deviceService.isTablet()) { //if client is on mobile
      str = "https://api.whatsapp.com/send?phone=%2B" + phone_number + "&text=Hi%2C%20I%20am%20interested%20in%20WeTrack%20";
    } else { //desktop
      str = 'https://web.whatsapp.com/send?phone=%2B' + phone_number + "&text=Hi%2C%20I%20am%20interested%20in%20WeTrack%20%";
    }

    // console.log(str);
    window.open(str);
  }

  createForm() {
    this.LoginForm = this.fb.group({
      email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      password: ['', Validators.required]
    });
  }

  checkLoggedIn() {
    if (this.loggedInService.checkStatus() != 0) {
      this.router.navigate(['/home']);
    }
  }

  login() {
    this.submitted = true;
    if (this.LoginForm.invalid) {
      this.loggedin = false;
      return;
    }
    this.loading = true;
    var self = this;
    var form_str = this.constructString();
    // this.loggedInService.logIn(form_str).subscribe(data => {
    //   this.loading = false;
    //   this.submitted = false;
    //   this.handleResponse(data);
    //   // console.log(data);
    // }, error => {
    //   // console.log('error');
    //   this.loading = false;
    //   this.handleResponse(error);
    // });
  }

  handleResponse(response) {
    if (response.status == 401) {
      this.unknownerror = false;
      this.loginerror = true;
      this.error_message = response.error.message;
      console.log(this.error_message);
    } else if (response.status == 1) {
      this.unknownerror = false;
      this.loggedin = true;
      const now = new Date();
      const wetrack_user = {
        user: response
        // expiry: now.getTime() + 86400000 //add one day to current time
      }
      localStorage.setItem('wetrack_user', JSON.stringify(wetrack_user));
      this.router.navigate(['/home']);
    } else {
      this.unknownerror = true;
      console.log('something wrong');
    }
  }

  constructString() {
    let form_data = this.LoginForm.value;
    return (JSON.stringify(form_data));
  }
}