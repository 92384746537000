<div id="mainNav">
    <nav id="header" class="landing-nav navbar navbar-expand-lg navbar-dark info-color">
        <a class="navbar-brand" href="#">
            <img class="" src="../assets/img/wetrack-logo.png" alt="logo">
        </a>
        <button class="navbar-toggler landing-navbar-toggler" id="landing-navbar-toggler" type="button"
            data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation">
            <!-- <i class="fas fa-bars"></i> -->
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon text-warning icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item hvr-backward">
                    <a class="nav-link waves-effect waves-light" href="#why-us-section">About Us</a>
                </li>
                <li class="nav-item hvr-backward">
                    <a class="nav-link waves-effect waves-light" href="#how-it-works-section">How It Works <span
                            class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item hvr-backward">
                    <a class="nav-link waves-effect waves-light" href="#partner-with-us-section">Get Started</a>
                </li>
                <li class="nav-item">
                    <!-- <button onclick="document.getElementById('idlogin').style.display='block'"
                        class="btn contact-us-btn">Log In</button> -->
                    <a href="https://assets.wetrack.co.ke"><button class="btn contact-us-btn">Log In</button></a>
                </li>
            </ul>
        </div>
    </nav>
</div>

<div class="sidebar landing-sidebar" id="landing-sidebar" data-color="purple" data-background-color="white"
    data-image="../assets/img/sidebar-1.jpg">
    <button class="navbar-toggler landing-sidebar-toggler" id="landing-sidebar-toggler" type="button"
        data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
        aria-expanded="false" aria-label="Toggle navigation">
        <!-- <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon text-warning icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span> -->
        X
    </button>
    <div class="logo border-bottom">
        <a href="#" class="sidebar-link home-logo logo-normal">
            <img class="" src="../assets/img/wetrack-logo.png" alt="logo">
        </a>
    </div>
    <div class="sidebar-wrapper border">
        <ul class="nav">
            <li class="nav-item cursor-pointer">
                <a class="nav-link waves-effect sidebar-link waves-light" href="#why-us-section">About Us</a>
            </li>
            <hr>
            <li class="nav-item cursor-pointer">
                <a class="nav-link sidebar-link waves-effect waves-light" href="#how-it-works-section">How It Works
                    <span class="sr-only">(How It Works)</span></a>
            </li>
            <hr>
            <li class="nav-item cursor-pointer">
                <a class="nav-link sidebar-link waves-effect waves-light" href="#partner-with-us-section">Partner With
                    Us</a>
            </li>
            <hr>
            <li class="nav-item sidebar-link cursor-pointer landing-sidebar-login-wrapper">
                <a href="https://assets.wetrack.co.ke">
                    <button class="btn contact-us-btn">Log In</button>
                </a>
            </li>
        </ul>
    </div>
</div>