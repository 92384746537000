<!--contact us form-->
<app-contact-form></app-contact-form>
<!--contact us form end-->

<!--Log In Form-->
<app-login-form></app-login-form>
<!--Log In Form End-->

<app-landing-navbar></app-landing-navbar>

<div id="contact-buttons-bar">
    <!-- <button class="contact-button-link show-hide-contact-bar">
        <span class="fa fa-angle-left"></span>
    </button> -->

    <a id="call-us-floating-btn" style="background-color: #0896D4;" href="tel:+254717089348"
        class="contact-button-link cb-ancor-email cb-ancor phone separated" title="Call us"><span
            class="fa fa-phone"></span>
    </a>
    <!-- <a style="background-color: #d93025;" href="mailto:sales@wetrack.co.ke" class="contact-button-link cb-ancor email"
        title="Send us an email"><span class="fa fa-envelope"></span>
    </a> -->
    <a href="#whatsapp" (click)="whatsappRedirect()" style="background-color: #37c638;"
        class="contact-button-link cb-ancor email " title="Chat on WhatsApp"><span class="fab fa-whatsapp"></span>
    </a>
</div>

<main class="main contact-main">
    <div id="landing-sidebar-modal" class="d-none"></div>

    <section class="landing-body contact-body mb-1 pb-5">
        <div class="row landing-inner-row">
            <!--Register form-->
            <app-register-form></app-register-form>
            <!--Register form end-->
        </div>
    </section>

    <section data-aos="" data-aos-duration="800" id="partner-with-us-section" class="before-footer-section pb-4">
        <!-- <div data-aos="fade-up" data-aos-duration="1000" class="row text-center align-center">
            <h3>
                Want To Partner With Us?
            </h3>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" class="row partner-btn-wrapper">
            <div class="align-center partner-btn-wrapper-inner">
                <button onclick="document.getElementById('id01').style.display='block'"
                    class="btn partner-btn hvr-rectangle-in">Become A
                    Partner</button>
            </div>
        </div> -->
        <div data-aos="zoom-in" data-aos-duration="500" class="footer-landing">
            <div class="row">
                <div class="col-md-4 col-sm-12 contacts-div ">
                    <div class="contacts-div-phone">
                        <h4>Call us on: 0717089348</h4>
                    </div>
                    <div class="contacts-div-email">
                        <h4>
                            Email us: sales@wetrack.co.ke
                        </h4>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 copyright-div text-center">
                    <p>
                        Copyright &#169; 2021 WeTrack
                    </p>
                </div>
                <div class="col-md-4 col-sm-12 text-right socials-div">
                    <div class="social-icons">
                        <a class="zukabit-link hvr-float" target="_blank"
                            href="https://www.facebook.com/wetracksystems/">
                            <i class="fab fa-facebook-square"></i></a>
                        <a class="zukabit-link hvr-float" target="_blank" href="https://twitter.com/WetrackSystems">
                            <i class="fab fa-twitter-square"></i></a>
                        <a class="zukabit-link hvr-float" target="_blank"
                            href="https://www.instagram.com/wetracksystems/">
                            <i class="fab fa-instagram"></i></a>
                    </div>
                    <div class="pr-2 pt-2">
                        <p>WeTrack Systems</p>
                    </div>
                    <div>
                        <p>A product of <a class="zukabit-link" target="_blank"
                                href="https://www.zukabit.co.ke">Zukabit</a> Solutions</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>

<!-- <section class="landing-footer">
    <div class="row">
        <div class="col-md-4">
            <ul class="text-left">
                <li class="">
                    Terms And Conditions
                </li>
                <li>
                    Privacy Policy
                </li>
            </ul>
        </div>
        <div class="col-md-4 text-center">
            <p>A Product Of <a href="https://www.zukabit.co.ke">Zukabit</a> Solutions</p>
        </div>
        <div class="col-md-4 text-right">
            <ul>
                <li>
                    <i class="fab fa-instagram"></i>
                </li>
                <li>
                    <i class="fab fa-facebook-square"></i>
                </li>
                <li>
                    <i class="fab fa-twitter-square"></i>
                </li>
                <li>
                    <i class="fab fa-linkedin"></i>
                </li>
            </ul>
        </div>
    </div>
    <hr>
    <div class="align-center bottommost">
        <p class="">Copyright &#169; 2020 Wetrack</p>
    </div>
</section> -->