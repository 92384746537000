///@types/googlemaps/index.d.ts' is not a module.///

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortalComponent } from './portal/portal.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './_forms/register/register.component';
import { HeadersComponent } from './_elements/headers/headers.component';
import { LoggedInService } from 'src/app/_services/logged-in-service.service';
import { LoginComponent } from './_forms/login/login.component';
import { HomepageComponent } from './homepage/homepage.component';
import { SideBarComponent } from './_elements/side-bar/side-bar.component';
import { NavBarComponent } from './_elements/nav-bar/nav-bar.component';
import { FooterComponent } from './_elements/footer/footer.component';
// import { AgmCoreModule } from '@agm/core';
import { } from 'googlemaps';
// import { AssetsComponent } from './assets/assets.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AccountComponent } from './account/account.component';
import { HttpClientModule } from '@angular/common/http';
import { ReportsComponent } from './reports/reports.component';
import { ReportsSideBarComponent } from './_elements/reports-side-bar/reports-side-bar.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LandingNavbarComponent } from './_elements/landing-navbar/landing-navbar.component';
import { PopperDirective } from './popper.directive';
import { HistorySidebarComponent } from './_elements/history-sidebar/history-sidebar.component';
import { MapComponent } from './map/map.component';
import { ContactFormComponent } from './_forms/contact-form/contact-form.component';
import { GeofencesComponent } from './geofences/geofences.component';
import { MyassetsComponent } from './myassets/myassets.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebviewerComponent } from './webviewer/webviewer.component';
import { ContactComponent } from './contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    PortalComponent,
    LoginComponent,
    RegisterComponent,
    HeadersComponent,
    HomepageComponent,
    SideBarComponent,
    NavBarComponent,
    FooterComponent,
    // AssetsComponent,
    PageNotFoundComponent,
    AccountComponent,
    ReportsComponent,
    ReportsSideBarComponent,
    LandingPageComponent,
    LandingNavbarComponent,
    PopperDirective,
    HistorySidebarComponent,
    MapComponent,
    ContactFormComponent,
    GeofencesComponent,
    MyassetsComponent,
    WebviewerComponent,
    ContactComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyCEzvHuTwyWfFxHN5JJOZtR9oRf1b-oI_M',
    //   libraries: ['places']
    // })
  ],
  providers: [LoggedInService],
  bootstrap: [AppComponent]
})
export class AppModule { }
