import { Injectable } from '@angular/core';
import { config } from '../_helpers/global';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  getDevices(user_api_hash) {
    return this.http.get<any>(`${config.apiUrl}/get_devices?user_api_hash=` + user_api_hash + `&lang=en`);
  }

  getReport(request_string) {
    return this.http.post<any>(`${config.apiUrl}/generate_report`, request_string, this.httpOptions);
  }

  getHistory(request_string, hash) {
    console.log(hash);

    return this.http.post<any>(`${config.apiUrl}/get_history?user_api_hash` + hash + `&lang=en`, request_string, this.httpOptions);
  }

  addGeofence(request_string) {
    return this.http.post<any>(`${config.apiUrl}/add_geofence?` + request_string, this.httpOptions);
  }

  getGeofences(user_api_hash) {
    return this.http.get<any>(`${config.apiUrl}/get_geofences?user_api_hash=` + user_api_hash + `&lang=en`);
  }

  deleteGeofence(user_api_hash, id) {
    return this.http.get<any>(`${config.apiUrl}/destroy_geofence?user_api_hash=` + user_api_hash + `&lang=en&geofence_id=` + id);
  }
}