import { Component, OnInit } from '@angular/core';
import { BroadcastService } from '../_services/broadcast.service';
import { ActivatedRoute, Router, NavigationStart ,NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {


  constructor( private router: Router, private BS : BroadcastService ) { }

  ngOnInit(): void {
  }

  toggleSidebar(header, tagline, report_type) {
    this.BS.toggleSideBar.emit({header, tagline, report_type});
    // console.log(header+"+"+tagline);
  }

}
