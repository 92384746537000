<!-- <a *ngIf="!loggedin" class="w3-bar-single-item login-btn"
onclick="document.getElementById('id01').style.display='block'">Login</a>
<a *ngIf="!loggedin" class="w3-bar-single-item login-btn"
onclick="document.getElementById('id02').style.display='block'">Sign Up</a> -->
<!--Login form-->
<div id="id01" class="modal">
    <div class="modal-inner">
        <app-login-form></app-login-form>
    </div>
</div>
<!--Login form END-->

<!--Register form-->
<!-- <div id="id02" class="modal">
    <div class="modal-inner RegisterForm">
        <app-register-form></app-register-form>
    </div>
</div> -->
<!--register form END-->


