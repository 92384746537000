import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { MyassetsComponent } from './myassets/myassets.component';
import { GeofencesComponent } from './geofences/geofences.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PortalComponent } from './portal/portal.component';
import { ReportsComponent } from './reports/reports.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: 'portal', component: PortalComponent },
  { path: 'home', component: HomepageComponent },
  { path: '', component: LandingPageComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'account', component: AccountComponent },
  { path: 'geofences', component: GeofencesComponent },
  { path: 'myassets', component: MyassetsComponent },
  { path: 'reports', component: ReportsComponent },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
