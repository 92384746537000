import { Component, OnInit } from '@angular/core';
import { config } from '../_helpers/global';
import { UserService } from '../_services/user.service';
import * as $ from 'jquery';
import { LoggedInService } from '../_services/logged-in-service.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})

export class AccountComponent implements OnInit {
  url : any;
  loading : any;
  user : any;
  hash : any;

  constructor( private US: UserService, private LS: LoggedInService ) { }

  ngOnInit(): void {
    this.loading = true;
    this.url = config.Url;  
    this.checkLoggedIn();
  }

  checkLoggedIn() {
    if (this.LS.checkStatus() != 0) {
      this.hash = this.LS.checkStatus();
      this.getUser();
    }
  }

  getUser() {
    this.US.get_user_data(this.hash).subscribe(data => {
      this.user = data;
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }

}
