<div id="idlogin" class="idlogin modal">
    <div class="modal-inner-login">
        <form [formGroup]="LoginForm" class="modal-content animate">
            <div class="align-center cancel-div-login">
				<h3 class="text-center pr-4	">Log In</h3>
                <button type="button" onclick="document.getElementById('idlogin').style.display='none'"
                    class="cancelbtn">X</button>
            </div>
			<!-- <div class="text-center align-center pl-2 pr-5 row">
			</div> -->
            <div class="row">
                <div class="col-md-12">
                    <div  class="email-input-wrapper form-group">
                        <label class="bmd-label-floating">Email Address</label>
                        <input [ngClass]="{'invalid-input': LoginForm.controls['email'].invalid && submitted}"  formControlName="email" id="login-email" required type="email" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="password-input-wrapper form-group">
                        <label class="bmd-label-floating">Password</label>
                        <input type="password" [ngClass]="{'invalid-input': LoginForm.controls['password'].invalid && submitted}" class="form-control" id="login-password" required formControlName="password">
                    </div>
                </div>
            </div>
            <div class="pl-3 pr-3">
                <div class="alert alert-danger p-3" *ngIf="LoginForm.invalid && submitted">Your credentials are invalid.</div>
            
                <div *ngIf="loginerror" class="alert alert-danger">
                
                    Your email or password is incorrect. Please check and try again.
                </div>
                <div *ngIf="unknownerror" class="alert alert-danger p-3">
                
                    Something Went wrong. Please try again
                </div>
                <div *ngIf="loggedin" class="alert alert-success p-3">
                    <strong>Success!</strong>
                    Logging you in...
                </div>
            </div>
            <div class="row">
                <div *ngIf="!loading" class="col-md-12">
                    <button (click)="login()" class="login-submit" type="submit">
                        <span>Continue</span> 
                    </button>
                </div>
                <div *ngIf="loading" class="col-md-12">
                    <button *ngIf="loading" (click)="login()"  class="login-submit" type="submit">
                        <span>Loading...</span>
                    </button>
                </div>
            </div>
            <div class="row forgot-password-footer">
                <div class="col-md-12">
                    <p>Forgot Password?  <span><a href="">Click Here</a></span></p>
                </div>
            </div>
        </form>
    </div>
</div>