import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { } from 'googlemaps';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { DevicesService } from '../_services/devices.service';
import { BroadcastService } from '../_services/broadcast.service';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class MapComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;

  mapProp: any;
  map: google.maps.Map;
  lat: number = -1.286389;
  lng: number = 36.817223;
  allCoordinates = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private DS: DevicesService, private BS: BroadcastService) {
    this.BS.toggleMapSidebar.subscribe(data => {  //Called from history sidebar on getHistory()
      let events = data.history.items;
      for (let i = 0; i < events.length; i++) {
        let startlat = events[0].items[0].lat;
        let startlng = events[0].items[0].lng;
        let stoplat = events[events.length - 1].items[0].lat;
        let stoplng = events[events.length - 1].items[0].lng;
        if (events[i].status == 3) { //route start
          let icon = 'assets/img/route_start.png';
          this.setCenter({ lat: startlat, lng: startlng });
          let marker = this.addCustomMarker(startlat, startlng, icon);
          let contentString = '<p><b style="font-weight: 700;">Route Start</b></p><p><b>Vehicle: </b>' + data.history.device['name'] + '</p><p><b>Location: </b>%location%</p><p><b>Owner: </b> ' + data.history.device['object_owner'] + '<p><p><b>Time: </b>' + events[i].items[0].time + '</p>';
          this.setPopupWindow(marker, startlat, stoplng, contentString);
        }
        else if (events[i].status == 4) { //route end
          let icon = 'assets/img/route_end.png';
          let marker = this.addCustomMarker(stoplat, stoplng, icon);
          let contentString = '<p><b style="font-weight: 700;">Route End</b></p><p><b>Vehicle: </b>' + data.history.device['name'] + '</p><p><b>Location: </b>%location%</p><p><b>Owner: </b> ' + data.history.device['object_owner'] + '<p><p><b>Time: </b>' + events[i].items[0].time + '</p>';
          this.setPopupWindow(marker, startlat, stoplng, contentString);
        }
        else if (events[i].status == 1) { //driving
          let coordinates = [];
          for (let p = 0; p < events[i].items.length; p++) {
            let object = events[i].items[p];
            let lat = object.lat;
            let lng = object.lng;
            let points = { lat: lat, lng: lng };
            coordinates.push(points); //for drawing on map

            //add coordinates to allCoordinates for setting zoom level on route
            let pts = [lat,lng];
            this.allCoordinates.push(pts);
          }
          this.addRoute(coordinates);
        }
        else if (events[i].status == 2 && data.form.stops == 1) { //stopped
          let lng = events[i].items[0].lng;
          let lat = events[i].items[0].lat;
          let marker;
          let icon = 'assets/img/route_stop.png';
          // if (lng != startlng && lat != startlng) { //prevent overlapping
            marker = this.addCustomMarker(lat, lng, icon);
          // }
          let contentString = '<p><b style="font-weight: 700;">Vehicle Stopped</b></p><p><b>Vehicle: </b>' + data.history.device['name'] + '</p><p><b>Location: </b>%location%</p><p><b>Owner: </b> ' + data.history.device['object_owner'] + '<p><p><b>Time: </b>' + events[i].show + ' <b>to</b> '+events[i].left+'</p><p><b>Stop Duration: </b>'+events[i].time+'</p>';
          this.setPopupWindow(marker, lat, lng, contentString);
        }
        else if (events[i].status == 5 && data.form.alerts == 1) { //alerts
          let lng = events[i].items[0].lng;
          let lat = events[i].items[0].lat;
          let icon = 'assets/img/route_event.png';
          let marker = this.addCustomMarker(lat, lng, icon);
          let contentString = '<p><b style="font-weight: 700;">' + events[i].message + '</b></p><p><b>Time: </b>' + events[i].show + '</p><p><b>Location: </b>%location%</p>';
          this.setPopupWindow(marker, startlat, stoplng, contentString);
        }
      }

      var bounds = new google.maps.LatLngBounds();
      for( let i = 0; i < this.allCoordinates.length; i++ ) {
        let latitude = this.allCoordinates[i][0];
        let longitude = this.allCoordinates[i][1];
        let location = new google.maps.LatLng(latitude, longitude);
        bounds.extend(location);
      }
      let self = this;
      google.maps.event.addListenerOnce(self.map, 'idle', function() {
        self.map.fitBounds(bounds);
      });
      // bounds.extend(this.allCoordinates);
      // bounds.extend(Item_1);
    });
    this.BS.resetMap.subscribe(data => {
      if (data) {
        this.resetMap();
      }
    });
  }

  ngOnInit(): void {
    this.mapProp = {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
  }

  ngAfterContentInit() {
    //initialise map after slight delay for dom to be ready
    setTimeout(() => {
      this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);
    }, 30);
    
  }

  addRoute(coordinates) {
    // const flightPath = new google.maps.Polyline({
    //   path: coordinates,
    //   strokeColor: "#FF0000",
    //   strokeOpacity: 1.0,
    //   strokeWeight: 3,
    // }); 
    const flightPath = new google.maps.Polyline({
      map: this.map,
      icons: [{
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          strokeColor: '#0000ff',
          fillColor: '#0000ff',
          fillOpacity: 1
        },
        repeat: '240px'
      }],
      path: coordinates
    });
    flightPath.setMap(this.map);
    // this.addStopAndStart(coordinates);
  }

  addStopAndStart(coordinates) {
    let startlat = coordinates[0].lat;
    let startlng = coordinates[0].lng;
    let icon = 'assets/img/route_start.png';
    this.addCustomMarker(startlat, startlng, icon);
    let stoplat = coordinates[coordinates.length - 1].lat;
    let stoplng = coordinates[coordinates.length - 1].lng;
    let icon2 = 'assets/img/route_end.png';
    this.addCustomMarker(stoplat, stoplng, icon2);
  }

  addCustomMarker(latitude, longitude, icon) { //add custom marker
    let lat = latitude;
    let lng = longitude;
    let coordinates = { lat, lng };
    var marker = new google.maps.Marker({
      position: coordinates, // Passing the coordinates
      map: this.map, //Map that we need to add
      draggable: false, // If set to true you can drag the marker
      icon: icon,
      animation: google.maps.Animation.DROP,
    });
    return marker;
  }

  resetMap() {
    this.ngOnInit();
    this.ngAfterContentInit();
  }

  setCenter(coordinates) {
    this.map.setCenter(coordinates);
  }

  setPopupWindow(marker, lat, lng, contentString) {
    var self = this;
    let location = 'Unnamed Road';
    //call location name from the coordinates and pass it to popup info window  
    const geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);
    geocoder.geocode({ 'location': latlng }, function (results, status) {
      if(results!=null && results[0]) {
        location = results[0].formatted_address;
      }
      // console.log(results);
      // console.log('lat'+lat+'lng'+lng);
      
      // console.log(location);
      
      contentString = contentString.replace("%location%", location);
      var information = new google.maps.InfoWindow({
        content: contentString
      });
      marker.addListener('click', function () {
        information.open(self.map, marker);
      });
    });
  }
}