import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
  }

  whatsappRedirect() {
    let phone_number = "254717089348";
    let currenturl = encodeURI(window.location.href);
    var str;
    let deviceInfo = this.deviceService.getDeviceInfo();


    if (this.deviceService.isMobile() || this.deviceService.isTablet()) { //if client is on mobile
      str = "https://api.whatsapp.com/send?phone=%2B" + phone_number + "&text=Hi%2C%20I%20am%20interested%20in%20WeTrack%20";
    } else { //desktop
      str = 'https://web.whatsapp.com/send?phone=%2B' + phone_number + "&text=Hi%2C%20I%20am%20interested%20in%20WeTrack%20%";
    }

    // console.log(str);
    window.open(str);
  }

}
