<div class="modal-inner">
    <form class="ContactForm modal-content animate" [formGroup]="ContactForm" method="post">
        <div class="align-left cancel-div-login">
            <h3 class="text-center contactFormHeader">Fill in the form below so we can schedule the installation.</h3>
            <button type="button" onclick="document.getElementById('id-register').style.display='none'"
                class="cancelbtn">X</button>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <label for="psw"><b>Name <span class="text-red">*</span></b></label>
                    <input formControlName="name" id="contact-name" type="text" placeholder="Your name" name="name"
                        required>
                    <div class="alert alert-danger"
                        *ngIf="ContactForm.controls['name'].invalid && (ContactForm.controls['name'].dirty || ContactForm.controls['name'].touched || submitted)">
                        Please provide your Name</div>
                </div>
                <div class="col-md-6">
                    <label for="email"><b>Email<span class="text-red"> *</span></b></label>
                    <input type="text" formControlName="email" id="login-email" placeholder="Enter your email"
                        name="email" required>
                    <div class="alert alert-danger"
                        *ngIf="ContactForm.controls['email'].invalid && (ContactForm.controls['email'].dirty || ContactForm.controls['email'].touched || submitted)">
                        Your Email is missing</div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 ">
                    <label for="location"><b>Location<span class="text-red"> *</span></b></label>
                    <select class="mt-2 custom-select border" name="location" formControlName="location">
                        <option value="nairobi">Nairobi</option>
                        <option value="mombasa">Mombasa</option>
                        <option value="baringo">Baringo</option>
                        <option value="bomet">Bomet</option>
                        <option value="bungoma">Bungoma</option>
                        <option value="busia">Busia</option>
                        <option value="elgeyo marakwet">Elgeyo Marakwet</option>
                        <option value="embu">Embu</option>
                        <option value="garissa">Garissa</option>
                        <option value="homa bay">Homa Bay</option>
                        <option value="isiolo">Isiolo</option>
                        <option value="kajiado">Kajiado</option>
                        <option value="kakamega">Kakamega</option>
                        <option value="kericho">Kericho</option>
                        <option value="kiambu">Kiambu</option>
                        <option value="kilifi">Kilifi</option>
                        <option value="kirinyaga">Kirinyaga</option>
                        <option value="kisii">Kisii</option>
                        <option value="kisumu">Kisumu</option>
                        <option value="kitui">Kitui</option>
                        <option value="kwale">Kwale</option>
                        <option value="laikipia">Laikipia</option>
                        <option value="lamu">Lamu</option>
                        <option value="machakos">Machakos</option>
                        <option value="makueni">Makueni</option>
                        <option value="mandera">Mandera</option>
                        <option value="meru">Meru</option>
                        <option value="migori">Migori</option>
                        <option value="marsabit">Marsabit</option>
                        <option value="mombasa">Mombasa</option>
                        <option value="muranga">Muranga</option>
                        <option value="nairobi">Nairobi</option>
                        <option value="nakuru">Nakuru</option>
                        <option value="nandi">Nandi</option>
                        <option value="narok">Narok</option>
                        <option value="nyamira">Nyamira</option>
                        <option value="nyandarua">Nyandarua</option>
                        <option value="nyeri">Nyeri</option>
                        <option value="samburu">Samburu</option>
                        <option value="siaya">Siaya</option>
                        <option value="taita taveta">Taita Taveta</option>
                        <option value="tana river">Tana River</option>
                        <option value="tharaka nithi">Tharaka Nithi</option>
                        <option value="trans nzoia">Trans Nzoia</option>
                        <option value="turkana">Turkana</option>
                        <option value="uasin gishu">Uasin Gishu</option>
                        <option value="vihiga">Vihiga</option>
                        <option value="wajir">Wajir</option>
                        <option value="pokot">West Pokot</option>
                    </select>
                    <div class="alert alert-danger"
                        *ngIf="ContactForm.controls['location'].invalid && (ContactForm.controls['location'].dirty || ContactForm.controls['location'].touched || submitted)">
                        Please provide your Location</div>
                </div>
                <div class="col-6">
                    <label for="phonenumber"><b>Phone Number</b></label>
                    <div>
                        <input formControlName="phonenumber" id="phonenumber" type="text" class="custom-select-input"
                            placeholder="0702123456" name="phonenumber" required>
                        <div class="alert alert-danger"
                            *ngIf="ContactForm.controls['phonenumber'].invalid && (ContactForm.controls['phonenumber'].dirty || ContactForm.controls['phonenumber'].touched)">
                            Invalid Phone Number </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label for="contact-message"><b>Notes</b></label>
                    <textarea formControlName="message" name="message"
                        placeholder="Any other additional information or questions." id="contact-message"
                        class="border form-control" rows="6"></textarea>
                    <div class="alert alert-danger"
                        *ngIf="ContactForm.controls['message'].invalid && (ContactForm.controls['message'].dirty || ContactForm.controls['message'].touched || submitted)">
                        Say something brief.</div>
                </div>
            </div>

            <div *ngIf="submiterror" class="alert alert-danger">
                <strong>Error!</strong>
                Sorry something went wrong. Please try again.
            </div>
            <div *ngIf="success" class="alert alert-success">
                <strong>Success!</strong>
                Your message was received. You will hear from us soon.
            </div>
        </div>

        <div class="container" style="background-color:#f1f1f1">
            <!-- <button type="button" onclick="document.getElementById('id01').style.display='none'" class="cancelbtn">Cancel</button> -->
            <a href="#contact">
                <button type="submit" *ngIf="!loading" id="new-installation-request-btn" (click)="onSubmit()"
                    name="submit-message" class="register-btn submit-message">Submit</button>
            </a>
            <button type="submit" (click)="onSubmit()" name="submit-message" class="register-btn submit-message"
                *ngIf="loading">Loading...</button>
            <!-- <div class="form-group">
                    <input type="submit" (click)="onSubmit()" value="Loading..." name="submit-message" class="form-control disabled submit-message" *ngIf="loading">
                </div> -->
        </div>
    </form>
</div>