import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { config } from '../../_helpers/global';

import * as $ from 'jquery';
import { ContactServiceService } from 'src/app/_services/contact-service.service';

@Component({
  selector: 'app-register-form',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  ContactForm: FormGroup;
  submitted = false;
  loading = false;
  submiterror = false;
  success = false;

  constructor(private fb: FormBuilder, private CS: ContactServiceService) { }

  ngOnInit(): void {
    this.ContactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      message: [''],
      location: ['', Validators.required],
      phonenumber: ['', [
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)
      ]]
    });
  }

  toggleRegister() {
    $('#id01').fadeOut();
    $('#id02').fadeIn();
  }

  toggleLogin() {
    $('#id02').css({ 'display': 'none' });
    $('#id01').css({ 'display': 'block' });
  }

  onSubmit() {
    this.submitted = true;
    if (this.ContactForm.invalid) {
      return;
    } else {
      this.loading = true;
      var formdata = this.ContactForm.value;
      this.CS.register(formdata).subscribe(data => {
        data = JSON.parse(data);
        this.loading = false;
        this.submitted = false;
        let error_code = parseInt(data[1], 10);
        if (error_code == 200) {
          this.ContactForm.reset();
          this.success = true;
          setTimeout(function () {
            this.success = false;
          }, 5000)
          this.submiterror = false;
        } else {
          console.log(data);
          this.loading = false;
          this.success = false;
          this.submiterror = true;
        }
      }, error => {
        this.loading = false;
        console.log(error);
        this.submiterror = true;
      })
    }
  }

}