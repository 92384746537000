<div id="myassets-wrapper" class="wrapper ">
    <!--sidebar-->
    <app-side-bar></app-side-bar>
    <!--sidebar End-->

    <!--history sidebar-->
    <app-history-sidebar></app-history-sidebar>
    <!--history sidebar end-->

    <!-- reports sidebar -->
    <div class="reports-background-modal" id="reports-background-modal" style="display: none;"></div>
    <app-reports-side-bar></app-reports-side-bar>
    <!-- reports sidebar end-->

    <!--contact us form-->
    <app-contact-form></app-contact-form>
    <!--contact us form end-->

    <div id="assets-list-section-main" class="main-panel assets-list-section-main">
        <!-- Navbar -->
        <!-- <app-nav-bar></app-nav-bar> -->
        <!-- End Navbar -->

        <div class="assets-header-section">
            <div class="intro-section">
                <div>
                    <h1>Assets</h1>
                </div>
                <div>
                    <p>A List Of All Your Tracked Assets Here</p>
                </div>
            </div>
        </div>

        <div class="assets-search-section">
            <div class="row">
                <div class="col-md-4 col-xs-4 col-sm-4 cursor-pointer" id="carsSectionToggle">
                    <h4 id="carsSectionToggleHeader" class="active">Vehicles</h4>
                </div>
                <!-- <div class="col-md-2 cursor-pointer" id="laptopsSectionToggle">
                    <h4 id="laptopsSectionToggleHeader">Laptops</h4>
                </div> -->
                <div class="col-md-8 col-sm-8 col-xs-8 search-section">
                    <div class="input-group no-border">
                        <input (keyup)="searchAssets()" id="search-assets" type="text" value="" class="form-control"
                            placeholder="Search...">
                        <button type="submit" (click)="searchAssets()" id="search-assets-submit"
                            class="btn btn-white btn-round btn-just-icon">
                            <i class="material-icons">search</i>
                            <div class="ripple-container"></div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="spinner-block pl-5 pt-2" *ngIf="loading">
            <div class="spinner spinner-3"></div>
        </div>

        <div id="no-asset-error" style="display: none;" class="p-5">
            <div class="alert alert-info">
                <span style="font-weight: 400;">There's no asset with that name. Please try again.</span>
            </div>
        </div>

        <div class="assets-list-section" id="carsSection">
            <div [@enter1] *ngFor="let car of cars; let i = index" class="row single-asset-card" id="car_{{i}}">
                <div class="col-md-3 col-sm-4">
                    <div *ngIf="car['online']  != 'ack'">
                        <i style="color: red;" class="fas fa-circle"></i>
                        Offline
                    </div>
                    <div *ngIf="car['online'] == 'ack'">
                        <i style="color: #0896D4;" class="fas fa-circle"></i>
                        Active
                    </div>
                    <img class="car-icon" src="../assets/img/car_side.png" alt="">
                </div>
                <div class="col-md-9 col-sm-8">
                    <div class="row asset-name-wrapper">
                        <div class="padding_bottom">
                            <h4 class="single-asset-plate">{{car['name']}}</h4>
                        </div>
                    </div>
                    <div class="row asset-action-buttons">
                        <div class="col-md-3 action-wrapper">
                            <div class="">
                                <!-- BMW X6 2020 -->
                            </div>
                            <p class="cursor-pointer" (click)="viewOnMap(i)">
                                <i class="fas fa-map" style="color: #0896D4;"></i>
                                View On Map
                            </p>
                        </div>
                        <div class="col-md-3 action-wrapper toggle-reports"
                            (click)="toggleSidebar('Vehicle Report', car['name'], i)">
                            <p class="cursor-pointer">
                                <i style="color: #0896D4;" class="fas  fa-clipboard"></i>
                                Generate Report
                            </p>
                        </div>
                        <div (click)="traceRoute(i)" class="col-md-3 trace-route action-wrapper">
                            <p class="cursor-pointer">
                                <!-- <i class="fas fa-sliders-h"></i> -->
                                <i style="color: #0896D4;" class="fas fa-route"></i>
                                Trace Route
                            </p>
                        </div>
                        <div class="col-md-3 action-wrapper report-problem ">
                            <p class="cursor-pointer"
                                onclick="document.getElementById('id-report-problem').style.display='block'">
                                <i style="color: #0896D4;" class="far fa-exclamation-triangle"></i>
                                Report Problem
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-1 asset-clearfix d-md-none"></div> -->
                <div class="clearfix"></div>
            </div>
        </div>
        <div style="display: none;" class="assets-list-section" id="laptopsSection">
            <!-- <div *ngFor="let car of cars; let i = index" class="row single-asset-card">
                <div class="col-md-2 pt-3 ">
                    <img class="car-icon" src="../assets/img/car_side.png" alt="">
                </div>
                <div class="col-md-2">
                    <div>
                        <h4 class="single-asset-plate">{{car['name']}}</h4>
                    </div>
                    <div class="pb-2">
                        BMW X6 2020
                    </div>
                    <div>
                        <i style="color: #0896D4;" class="fas fa-circle"></i>
                        Live
                    </div>
                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i class="fas fa-map" style="color: #0896D4;"></i>
                        View On Map
                    </p>
                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i style="color: #0896D4;" class="fas fa-history"></i>
                        Vehicle History
                    </p>
                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i style="color: #0896D4;"  class="fas fa-sliders-h"></i>
                    Vehicle Settings
                    </p>
                   
                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i style="color: #0896D4;"  class="far fa-exclamation-triangle"></i>
                        Report Problem
                    </p>
                    
                </div>
            </div> -->

            <div class="row single-asset-card">
                <div class="col-md-2 pt-3 ">
                    <img class="car-icon" src="../assets/img/laptop.png" alt="">
                </div>
                <div class="col-md-2">
                    <div>
                        <h4 style="font-size: 20px;" class="single-asset-plate">Office Laptop</h4>
                    </div>
                    <div class="pb-2">
                        Macbook Pro 2020
                    </div>
                    <div>
                        <i style="color: #0896D4;" class="fas fa-circle"></i>
                        Live
                    </div>
                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i class="fas fa-map" style="color: #0896D4;"></i>
                        View On Map
                    </p>
                </div>
                <div class="toggle-reports col-md-2 action-wrapper">
                    <p>
                        <i style="color: #0896D4;" class="fas fa-history"></i>
                        Laptop History
                    </p>
                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i style="color: #0896D4;" class="fas fa-sliders-h"></i>
                        Laptop Settings
                    </p>
                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i style="color: #0896D4;" class="far fa-exclamation-triangle"></i>
                        Report Problem
                    </p>

                </div>
            </div>
            <div class="row single-asset-card">
                <div class="col-md-2 pt-3 ">
                    <img class="car-icon" src="../assets/img/laptop.png" alt="">
                </div>
                <div class="col-md-2">
                    <div>
                        <h4 style="font-size: 20px;" class="single-asset-plate">Office Laptop</h4>
                    </div>
                    <div class="pb-2">
                        Hp Omen 520
                    </div>
                    <div>
                        <i style="color: #0896D4;" class="fas fa-circle"></i>
                        Live
                    </div>
                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i class="fas fa-map" style="color: #0896D4;"></i>
                        View On Map
                    </p>
                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i style="color: #0896D4;" class="fas fa-history"></i>
                        Laptop History
                    </p>

                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i style="color: #0896D4;" class="fas fa-sliders-h"></i>
                        Laptop Settings
                    </p>

                </div>
                <div class="col-md-2 action-wrapper">
                    <p>
                        <i style="color: #0896D4;" class="far fa-exclamation-triangle"></i>
                        Report Problem
                    </p>

                </div>
            </div>
        </div>
        <!--footer-->
        <app-footer></app-footer>
        <!--footer END-->
    </div>
</div>