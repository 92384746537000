<div class="sidebar-right" id="sidebar-right" >
    <!-- <div class="logo align-center p-3 border">
      <h1>General Report</h1>
    </div> -->
    <div class="sidebar-wrapper ">
        <div class="sidebar-inner">
            <div class="">
              <button id="close-report-side" class="btn close-report-side btn-danger">X</button>
              <h2 class="card-title text-center">{{header}}</h2>
              <p class="card-category text-center">{{tagline}}</p>
              <hr>
            </div>
            <div *ngIf="!reportloaded" class=" pl-5 pr-5">
              <form [formGroup]="ReportsForm">
                <div class="row">
                  <div class="col-md-6 col-lg-3 col-sm-6">
                    <div class="form-group">
                      <!-- <label class="bmd-label-floating">Username</label>
                      <input type="text" class="form-control"> -->
                        <button type="submit" id="custom-date-24" class="pull-left date-active btn date-button btn-info">24 Hrs</button>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3 col-sm-6">
                    <div class="form-group ">
                      <!-- <label class="bmd-label-floating">Username</label>
                      <input type="text" class="form-control"> -->
                        <button type="submit" id="custom-date-7" class="pull-left btn btn-info date-button">7 days</button>
                    </div>
                  </div>   
                  <div class="col-md-6 col-lg-3 col-sm-6">
                    <div class="form-group">
                      <!-- <label class="bmd-label-floating">Username</label>
                      <input type="text" class="form-control"> -->
                        <button type="submit" id="custom-date-30" class="pull-left btn btn-info date-button">30 days</button>
                    </div>  
                  </div>
                  <div class="col-md-6 col-lg-3 col-sm-6">
                    <div class="form-group">
                      <!-- <label class="bmd-label-floating">Username</label>
                      <input type="text" class="form-control"> -->
                        <button id="custom-date" type="submit" class="pull-left btn btn-info date-button">Custom</button>
                    </div>
                  </div>
                </div>
                <div id="date-selector" class="d-none row pt-4">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="">Date From:</label>
                      <input type="date" name="date_from" formControlName="date_from" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="">Date To:</label>
                      <input type="date" name="date_to" formControlName="date_to" class="form-control">
                    </div>
                  </div>
                </div>
                <div id="devices_select_wrapper" class="row pt-2">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="">Devices:</label>
                        <select style="display: block; width: 100%;"  name="devices" formControlName="devices" id="sidebar-select-devices" class="browser-default custom-select" multiple >
                            <option *ngFor="let device of devices" value="{{device['id']}}">
                              {{device['name']}}
                            </option>
                        </select>
                    </div>
                  </div>
                </div>
                <div id="report_type_wrapper" class="row pt-2">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="">Report Type: <span style="color: red;"> *</span></label>
                        <select style="display: block; width: 100%;" id="sidebar-select-devices" class="browser-default custom-select"  name="report_type" [class.invalid-input]="ReportsForm.controls['report_type'].invalid && submitted"   formControlName="report_type">
                            <option value="1" >
                                General Report
                            </option>
                            <option value="3">
                                Drives and Stops
                            </option>
                            <option value="4">
                                Travel Sheet
                            </option>
                            <option value="5">
                                Overspeed
                            </option>
                            <option value="38">
                                Offline Objects
                            </option>
                        </select>
                    </div>
                  </div>
                </div>
                <div class="row pt-2">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="d-block">Export As:</label>
                      <div class="row">
                          <div class="col-md-4">
                              <input id="export-pdf" type="radio" formControlName="export_as"
                              name="export_as" value="pdf" class="" >
                              <label for="export-pdf"><i class="fas fa-file-pdf" style="color: red;font-size: 30px"></i> PDF</label>
                          </div>
                          <div class="col-md-4">
                              <input type="radio" id="export-xls" value="xls" formControlName="export_as" name="export_as" class="">
                              <label for="export-xls"><i class="fas fa-file-spreadsheet" style="color: #00990d; font-size: 30px"></i> XLS</label>
                          </div>
                          <div class="clearfix"></div>
                      </div>                      
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <button type="submit" *ngIf="!loadingreport" (click)="getReport()" style="width: 100%;" class="btn btn-success">Generate Report</button>
                    <button *ngIf="loadingreport" class="btn btn-disabled" style="width: 100%;">Generating Report...</button>
                  </div>
                </div>
                <div class="clearfix"></div>
              </form>
            </div>
            <div *ngIf="reportloaded">
              <div class="reports-buttons">
                <div class="reports-container">
                    <h3>View or Download your report Below.</h3>

                    <div class="align-center reports-button-inner row">
                      <div *ngIf="isPDF == 'pdf'" class="col-md-6">
                        <a (click)="openPdf(anchor_tag)" class="reports-btn effect04 mb-3" data-sm-link-text="View" ><span><i class="material-icons">visibility</i> View</span></a>
                      </div>
                      <div class="col-md-6">
                         <a  href="{{anchor_tag}}" class="reports-btn effect04" data-sm-link-text="Download" ><span><i class="material-icons">get_app</i> Download</span></a>
                      </div>          
                    </div>                
                </div>
              </div>
            </div>
            <!-- <div id="pdfviewer">
              <div #viewer class="viewer"></div>
            </div> -->
          </div>
    </div>
</div>

<div id="pdf-viewer" class="pdf-viewer">
  <button class="btn btn-danger close-pdf-viewer" id="close-pdf-viewer" (click)="closePdf()">X</button>
  <div class="pdf-viewer-inner">
    <div class="row align-center">
      <div *ngIf="pdfLoading" class="spinner-block pl-5 pt-2">
        <div class="spinner spinner-3"></div>
      </div>
    </div>
    <web-viewer></web-viewer>
  </div>
</div>