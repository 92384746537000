<!--contact us form-->
<app-contact-form></app-contact-form>
<!--contact us form end-->

<!--Register form-->
<div id="id-register" class="modal contact-modal">
    <app-register-form></app-register-form>
</div>
<!--Register form end-->

<!--Log In Form-->
<app-login-form></app-login-form>
<!--Log In Form End-->

<app-landing-navbar></app-landing-navbar>

<div id="contact-buttons-bar">
    <!-- <button class="contact-button-link show-hide-contact-bar">
        <span class="fa fa-angle-left"></span>
    </button> -->

    <a id="call-us-floating-btn" style="background-color: #0896D4;" href="tel:+254717089348"
        class="contact-button-link cb-ancor-email cb-ancor phone separated" title="Call us"><span
            class="fa fa-phone"></span>
    </a>
    <!-- <a style="background-color: #d93025;" href="mailto:sales@wetrack.co.ke" class="contact-button-link cb-ancor email"
        title="Send us an email"><span class="fa fa-envelope"></span>
    </a> -->
    <a href="#whatsapp" (click)="whatsappRedirect()" style="background-color: #37c638;"
        class="contact-button-link cb-ancor email " title="Chat on WhatsApp"><span class="fab fa-whatsapp"></span>
    </a>
</div>

<main class="main">
    <div id="landing-sidebar-modal" class="d-none"></div>
    <div class="landing-body">
        <section class="landing-inner">
            <div class="row landing-inner-row">
                <div class="col-md-7  landing-intro-right ">
                    <div [@faderight] [@leaveright] class="hero-right-wrapper">
                        <img src="assets/img/Rectangle_maps.png" alt="">
                    </div>
                </div>
                <div class="col-md-5   landing-intro-left">
                    <div [@enter1] [@leave1] class="hero-left-wrapper">
                        <h2>
                            VEHICLE GPS TRACKING SOLUTION
                        </h2>
                        <P>We Track, Worry Less!!</P>
                    </div>
                    <div>
                        <a href="/contact">
                            <button [@pulse]="animationState" (@pulse.done)="pulseDone()"
                                class="btn get-started-btn ">Get Started</button>
                            <!-- <button [@pulse]="animationState" (@pulse.done)="pulseDone()"
                                onclick="document.getElementById('id-register').style.display='block'"
                                class="btn get-started-btn ">Get Started</button> -->
                        </a>
                    </div>
                    <div [@enter3] [@leave3] class="track-assets-section">
                        <div>
                            <h5>
                                Track your assets on the move.
                            </h5>
                        </div>
                        <div class="row ">
                            <div class="col-sm-3 col-md-6 col-6 no-padding">
                                <a target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.zukabit.wetrackcustom">
                                    <img src="assets/img/googleplay.png" class="hvr-float store-img" alt="">
                                </a>
                            </div>
                            <!-- <div class="col-6 col-md-6 col-sm-3 no-padding">
                                <img src="assets/img/appstore.png" class="hvr-float store-img apple-store" alt="">
                            </div> -->
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <section id="why-us-section" data-aos="fade-up" data-aos-duration="800" class=" why-us-outer">
        <div class="why-us">
            <div>
                <h2 data-aos="flip-down" data-aos-duration="1400">
                    Why Us
                </h2>
            </div>
        </div>
        <div class="why-us-intro">
            <p data-aos="fade-right" data-aos-duration="1000" class="why-us-explanation text-center">WeTrack is the
                leading assets tracking solution provider in Kenya. We offer high-tech solutions ranging from car
                tracking,
                motorcycle tracking to fleet management and valuable assets tracking services.
            </p>
        </div>
        <div class="why-us-section text-center">
            <div class="row">
                <div class="col-md-3" data-aos="fade-up-right" data-aos-duration="800">
                    <div class="improved-profits-div">
                        <img class="monitor-analytics" src="assets/img/profits.svg" alt="">
                    </div>
                    <div>
                        <h3>
                            Improved Profitability
                        </h3>
                    </div>
                </div>
                <div data-aos="fade-up-left" data-aos-duration="800" class="col-md-3">
                    <div>
                        <img class="monitor-analytics" src="assets/img/monitor.png" alt="">
                    </div>
                    <div>
                        <h3>
                            Advanced Reporting
                        </h3>
                    </div>
                </div>
                <div data-aos="fade-up-right" data-aos-duration="800" class="col-md-3">
                    <div>
                        <img class="monitor-analytics" src="assets/img/time-management.png" alt="">
                    </div>
                    <div>
                        <h3>
                            Efficient Productivity
                        </h3>
                    </div>
                </div>
                <div data-aos="fade-up-left" data-aos-duration="800" class="col-md-3">
                    <div>
                        <img class="monitor-analytics" src="assets/img/checklist.png" alt="">
                    </div>
                    <div>
                        <h3>
                            Informed Asset Valuation
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="landing-inner">
        <div class="row">
            <div class="col-md-6 landing-inner-left">
                <h1>Assets GPS Tracking Solution</h1>
            </div>
            <div class="col-md-6 landing-inner-right">
                <div class=" login-card">
                    <div class="login-card-header pb-3">
                        <h3 class="">Log In</h3>
                        <p>Access your account</p>
                    </div>
                    <div class="login-card-body">
                        <form [formGroup]="LoginForm">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="email-input-wrapper form-group">
                                        <label class="bmd-label-floating">Email Address</label>
                                        <input
                                            [ngClass]="{'invalid-input': LoginForm.controls['email'].invalid && submitted}"
                                            formControlName="email" id="login-email" required type="email"
                                            class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="password-input-wrapper form-group">
                                        <label class="bmd-label-floating">Password</label>
                                        <input type="password"
                                            [ngClass]="{'invalid-input': LoginForm.controls['password'].invalid && submitted}"
                                            class="form-control" id="login-password" required
                                            formControlName="password">
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-danger" *ngIf="LoginForm.invalid && submitted">Your credentials are
                                invalid.</div>

                            <div *ngIf="loginerror" class="alert alert-danger">

                                Your email or password is incorrect. Please check and try again.
                            </div>
                            <div *ngIf="unknownerror" class="alert alert-danger">

                                Something Went wrong. Please try again
                            </div>
                            <div *ngIf="loggedin" class="alert alert-success">
                                <strong>Success!</strong>
                                Logging you in...
                            </div>
                            <div class="row">
                                <div *ngIf="!loading" class="col-md-12">
                                    <button (click)="login()" class="login-submit" type="submit">
                                        <span>Continue</span>
                                    </button>
                                </div>
                                <div *ngIf="loading" class="col-md-12">
                                    <button *ngIf="loading" (click)="login()" class="login-submit" type="submit">
                                        <span>Loading...</span>
                                    </button>
                                </div>
                            </div>
                            <div class="row forgot-password-footer">
                                <div class="col-md-12">
                                    <p>Forgot Password? <span><a href="">Click Here</a></span></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <section data-aos="fade-up" data-aos-duration="800" class="ideal-features-section">
        <div class="row">
            <div class="col-md-12 col-lg-5">
                <div>
                    <h3 data-aos="fade-up" data-aos-duration="800">
                        Features of Our Product</h3>
                </div>
                <div>
                    <ul data-aos="fade-up" data-aos-duration="800" class="features-list">
                        <li>
                            Geographical Fencing and Multiple Car Tracking
                        </li>
                        <li>
                            Battery tampering Alerts and Built-in Backup battery
                        <li>
                            Vehicle immobilization and idle time monitoring module.
                        </li>
                        <li>
                            Automated time, distance and location reporting.
                        </li>
                        <li>
                            Ignition on Alerts via SMS
                        </li>
                        <li>
                            24/7 control centre for support and monitoring
                        </li>
                        <li>
                            Driver Performance Analysis
                        </li>
                        <li>
                            Fuel Monitoring, Mileage Reporting and Speed Analysis
                        </li>
                    </ul>
                </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="800" data-aos-anchor-placement="top-bottom"
                class="col-md-12 col-lg-7">
                <div class="tracker-package-wrapper">
                    <img class="tracker-package" src="assets/img/tracker_package.png" alt="">
                </div>
            </div>
        </div>
    </section>

    <section data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="800" id="how-it-works-section"
        class="how-it-works">
        <div class="how-it-works-header text-center">
            <h2>
                How WeTrack Works
            </h2>
        </div>
        <div class="how-it-works-follow text-center align-center">
            <h5 data-aos="fade-up" data-aos-duration="1000">
                In 3 Simplified Steps
            </h5>
        </div>
        <div class="row how-it-works-steps text-center">
            <div data-aos-duration="800" data-aos="flip-left" data-aos-anchor-placement="top-center"
                class="col-md-12 col-lg-4">
                <div class="">
                    <img class="illustrations-shadow " src="assets/img/onboarding.png" alt="">
                </div>
                <div class="how-it-works-text">
                    <h4>Register as a User.</h4>
                </div>
            </div>
            <div data-aos-duration="800" data-aos="flip-left" data-aos-anchor-placement="top-center"
                class="col-md-12 col-lg-4">
                <div class="">
                    <img class="illustrations-shadow" src="assets/img/mobilemap2.png" alt="">
                </div>
                <div class="how-it-works-text">
                    <h4>View your Assets both on maps and assets tab.</h4>
                </div>
            </div>
            <div data-aos-duration="800" data-aos="flip-left" data-aos-anchor-placement="top-center"
                class="col-md-12 col-lg-4">
                <div class="">
                    <img class="illustrations-shadow" src="assets/img/options.png" alt="">
                </div>
                <div class="how-it-works-text">
                    <h4>Generate different types of reports.</h4>
                </div>
            </div>
        </div>
        <!-- <div   
            <div data-aos-duration="800" data-aos="flip-left" data-aos-anchor-placement="top-center"
                class="col-md-12 col-lg-4">
                <div class="">
                    <img class="illustrations-shadow " src="assets/img/onboarding.png" alt="">
                </div>
                <div class="how-it-works-text">
                    <h4>Register as a User.</h4>
                </div>
            </div>
            <div data-aos-duration="800" data-aos="flip-left" data-aos-anchor-placement="top-center"
                class="col-md-12 col-lg-4">
                <div class="">
                    <img class="illustrations-shadow" src="assets/img/mobilemap2.png" alt="">
                </div>
                <div class="how-it-works-text">
                    <h4>View your Assets both on maps and assets tab.</h4>
                </div>
            </div>
            <div data-aos-duration="800" data-aos="flip-left" data-aos-anchor-placement="top-center"
                class="col-md-12 col-lg-4">
                <div class="">
                    <img class="illustrations-shadow" src="assets/img/options.png" alt="">
                </div>
                <div class="how-it-works-text">
                    <h4>Generate different types of reports.</h4>
                </div>
            </div>
        </div> -->
    </section>

    <section data-aos="fade-up" data-aos-duration="800" id="partner-with-us-section"
        class="before-footer-register-section">
        <div data-aos="fade-up" data-aos-duration="1000" class="row text-center align-center">
            <h3>
                Book your installation.
            </h3>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" class="row partner-btn-wrapper">
            <div class="align-center partner-btn-wrapper-inner">

                <a href="/contact">
                    <!-- <button onclick="document.getElementById('id-register').style.display='block'"
                        class="btn bottom-register-btn ">Get Started</button> -->
                    <button class="btn bottom-register-btn ">Get Started</button>
                </a>

            </div>
        </div>
    </section>

    <section data-aos="" data-aos-duration="800" id="partner-with-us-section" class="before-footer-section pb-4">
        <!-- <div data-aos="fade-up" data-aos-duration="1000" class="row text-center align-center">
            <h3>
                Want To Partner With Us?
            </h3>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" class="row partner-btn-wrapper">
            <div class="align-center partner-btn-wrapper-inner">
                <button onclick="document.getElementById('id01').style.display='block'"
                    class="btn partner-btn hvr-rectangle-in">Become A
                    Partner</button>
            </div>
        </div> -->
        <div data-aos="zoom-in" data-aos-duration="500" class="footer-landing">
            <div class="row">
                <div class="col-md-4 col-sm-12 contacts-div ">
                    <div class="contacts-div-phone">
                        <h4>Call us on: 0717089348</h4>
                    </div>
                    <div class="contacts-div-email">
                        <h4>
                            Email us: sales@wetrack.co.ke
                        </h4>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 copyright-div text-center">
                    <p>
                        Copyright &#169; 2021 WeTrack
                    </p>
                </div>
                <div class="col-md-4 col-sm-12 text-right socials-div">
                    <div class="social-icons">
                        <a class="zukabit-link hvr-float" target="_blank"
                            href="https://www.facebook.com/wetracksystems/">
                            <i class="fab fa-facebook-square"></i></a>
                        <a class="zukabit-link hvr-float" target="_blank" href="https://twitter.com/WetrackSystems">
                            <i class="fab fa-twitter-square"></i></a>
                        <a class="zukabit-link hvr-float" target="_blank"
                            href="https://www.instagram.com/wetracksystems/">
                            <i class="fab fa-instagram"></i></a>
                    </div>
                    <div class="pr-2 pt-2">
                        <p>WeTrack Systems</p>
                    </div>
                    <div>
                        <p>A product of <a class="zukabit-link" target="_blank"
                                href="https://www.zukabit.co.ke">Zukabit</a> Solutions</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<!-- <section class="landing-footer">
    <div class="row">
        <div class="col-md-4">
            <ul class="text-left">
                <li class="">
                    Terms And Conditions
                </li>
                <li>
                    Privacy Policy
                </li>
            </ul>
        </div>
        <div class="col-md-4 text-center">
            <p>A Product Of <a href="https://www.zukabit.co.ke">Zukabit</a> Solutions</p>
        </div>
        <div class="col-md-4 text-right">
            <ul>
                <li>
                    <i class="fab fa-instagram"></i>
                </li>
                <li>
                    <i class="fab fa-facebook-square"></i>
                </li>
                <li>
                    <i class="fab fa-twitter-square"></i>
                </li>
                <li>
                    <i class="fab fa-linkedin"></i>
                </li>
            </ul>
        </div>
    </div>
    <hr>
    <div class="align-center bottommost">
        <p class="">Copyright &#169; 2020 Wetrack</p>
    </div>
</section> -->