<div class="sidebar" data-color="purple" data-background-color="white" data-image="../assets/img/sidebar-1.jpg">
    <!--
      Tip 1: You can change the color of the sidebar using: data-color="purple | azure | green | orange | danger"

      Tip 2: you can also add an image using data-image tag
  -->
    <div class="logo border">
      <a routerLink="/home" class="home-logo logo-normal">
        <img class="" src="../assets/img/wetrack-logo.png" alt="logo">
      </a>
    </div>
    <div class="sidebar-wrapper">
      <ul class="nav">
        <li class="nav-item cursor-pointer"  routerLinkActive="active" routerLink="/home">
          <a class="nav-link">
            <i class="fas fa-map"></i>
            <p>Map</p>
          </a>
        </li>
        <li class="nav-item cursor-pointer" routerLinkActive="active" routerLink="/myassets">
          <a class="nav-link">
            <i class="far fa-steering-wheel"></i>
            <p>Assets</p>
          </a>
        </li>
        <li class="nav-item cursor-pointer" routerLinkActive="active" routerLink="/reports">
          <a class="nav-link">
            <!-- <i class="material-icons">content_paste</i> -->
            <i class="far fa-clipboard"></i>
            <p>Reports</p>
          </a>
        </li>
        <li class="nav-item cursor-pointer" routerLinkActive="active" routerLink="/geofences">
          <a class="nav-link">
            <i class="fas fa-draw-polygon"></i>
            <p>Geofences</p>
          </a>
        </li>
        <!-- <li class="nav-item cursor-pointer" routerLinkActive="active" routerLink="/settings">
          <a class="nav-link" href="./typography.html">
            <i class="fas fa-cog"></i>
            <p>Settings</p>
          </a>
        </li> -->
        <li class="nav-item cursor-pointer" routerLinkActive="active" routerLink="/account">
          <a class="nav-link">
            <i class="fas fa-user-circle"></i>
            <p>Account</p>
          </a>
        </li>
        <li (click)="logOut()" style="color: red;" class="nav-item active-pro cursor-pointer">
          <a class="nav-link" style="color: red;" >
            <i style="color: red;" class="fal fa-sign-out"></i>
            <p  style="color: red;">Logout</p>
          </a>
        </li>
      </ul>
    </div>
</div>

<div class="main-panel top-nav-bar">
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg navbar-absolute fixed-top ">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <!-- <a class="" href="javascript:;">Map</a> -->
        <a routerLink="/home" href="home" class="topbar-logo navbar-brand ">
          <img class="" src="../assets/img/wetrack-logo.png" alt="logo">
        </a>
      </div>
      <button class="navbar-toggler text-warning" type="button" id="navbar-toggler" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon text-warning icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
      </button>
      <!-- <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="material-icons">person</i>
              <p class="d-lg-none d-md-block">
                Account
              </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
              <a class="dropdown-item" href="#">Profile</a>
              <a class="dropdown-item" href="#">Settings</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Log out</a>
            </div>
          </li>
        </ul>
      </div> -->
    </div>
  </nav>
  <!-- End Navbar -->
  <div id="map"></div>
</div>