<div class="wrapper ">
  <!--sidebar-->
  <app-side-bar></app-side-bar>
  <!--sidebar End-->

  <div class="main-panel">
    <!-- Navbar -->
    <!-- <app-nav-bar></app-nav-bar> -->
    <!-- End Navbar -->

    <div class="map-wrapper-home">
      <div #gmap style="width:100%;height:100%"></div>
    </div>
    <!--footer-->
    <app-footer></app-footer>
    <!--footer END-->
  </div>
</div>