import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core'
// import { } from 'googlemaps';
// import { AgmCoreModule } from '@agm/core';
import { } from 'googlemaps';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { DevicesService } from '../_services/devices.service';
import { LoggedInService } from '../_services/logged-in-service.service';
import { start } from 'repl';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})

export class HomepageComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;

  url:any;
  hash : any;
  cars = [];
  route: any;
  locations = [];
  loading = false;
  mapProp : any;
  map: google.maps.Map;
  lat: number = -1.286389;
  lng: number = 36.817223;
  carID : any;
  interval : any;
  markers = [];

  constructor( private router:Router, private activatedRoute:ActivatedRoute, private DS: DevicesService, private LS: LoggedInService ) {
    this.router.getCurrentNavigation().extras.state;
    if(history.state) { //if there is a state object
      if(history.state.car) { //if state contains a car
        this.lat = history.state.car['lat'];
        this.lng = history.state.car['lng'];
        this.carID = history.state.car['id'];
      }
    }
  }

  ngOnInit(): void {
    if(history.state.car) {  //if state contains a car, set single marker, lat & lng from constructor
      this.mapProp = {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };   
    } else { //if state doesn't contain a car set markers for all vehicles in the account
      this.checkLoggedIn();
      this.mapProp = {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: 6,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }; 
    }
  }

  ngAfterContentInit() {
    //initialise map after slight delay for dom to be ready
    setTimeout(() => {
      this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);
      if (history.state.car) {
        this.addMarker(history.state.car);
        // this.setMapsInterval();
        this.checkLoggedIn();
      }
    }, 30);
  }

  checkLoggedIn() {
    if (this.LS.checkStatus() != 0) {
      this.hash = this.LS.checkStatus();      
      this.getDevices(this.hash);
    }
  }

  addStopAndStart(coordinates) {
    let startlat = coordinates[0].lat;
    let startlng = coordinates[0].lng;
    let icon = 'assets/img/route_start.png';
    this.addCustomMarker(startlat, startlng, icon);
    // console.log(startlat);
    let stoplat = coordinates[coordinates.length -1].lat;
    let stoplng = coordinates[coordinates.length -1].lng;
    let icon2 = 'assets/img/route_end.png';
    this.addCustomMarker(stoplat,stoplng, icon2);
  }

  addCustomMarker(latitude,longitude, icon) { //add custom marker
    let lat = latitude;
    let lng = longitude;
    let coordinates = {lat,lng};
    var marker = new google.maps.Marker({
       position: coordinates, // Passing the coordinates
       map:this.map, //Map that we need to add
       draggable: false, // If set to true you can drag the marker
       icon: icon,
      //  animation: google.maps.Animation.DROP,
    });
  }

  composeIcon(angle, online) {
    let y;
    if (angle == 0) {
      y = 0;
    } else if (angle > 0 && angle <= 10) {
      y = 350;
    } else if (angle > 10 && angle <= 20) {
      y = 340;
    } else if (angle > 20 && angle <= 30) {
      y = 330;
    } else if (angle > 30 && angle <= 40) {
      y = 320;
    } else if (angle > 40 && angle <= 50) {
      y = 310;
    } else if (angle > 50 && angle <= 60) {
      y = 300;
    } else if (angle > 60 && angle <= 70) {
      y = 290;
    } else if (angle > 70 && angle < 90) {
      y = 280;
    } else if (angle == 90) {
      y = 270;
    } else if (angle > 90 && angle <= 100) {
      y = 260;
    } else if (angle > 100 && angle <= 110) {
      y = 250;
    } else if (angle > 110 && angle <= 120) {
      y = 240;
    } else if (angle > 120 && angle <= 130) {
      y = 230;
    } else if (angle > 130 && angle <= 140) {
      y = 220;
    } else if (angle > 140 && angle <= 150) {
      y = 210;
    } else if (angle > 150 && angle <= 160) {
      y = 200;
    } else if (angle > 160 && angle < 180) {
      y = 190;
    } else if (angle == 180) {
      y = 180;
    } else if (angle > 180 && angle <= 190) {
      y = 170;
    } else if (angle > 190 && angle <= 200) {
      y = 160;
    } else if (angle > 200 && angle <= 210) {
      y = 150;
    } else if (angle > 210 && angle <= 220) {
      y = 140;
    } else if (angle > 220 && angle <= 230) {
      y = 130;
    } else if (angle > 230 && angle <= 240) {
      y = 120;
    } else if (angle > 240 && angle <= 250) {
      y = 110;
    } else if (angle > 250 && angle < 270) {
      y = 100;
    } else if (angle == 270) {
      y = 90;
    } else if (angle > 270 && angle <= 280) {
      y = 80;
    } else if (angle > 280 && angle <= 290) {
      y = 70;
    } else if (angle > 290 && angle <= 300) {
      y = 60;
    } else if (angle > 300 && angle <= 310) {
      y = 50;
    } else if (angle > 310 && angle <= 320) {
      y = 40;
    } else if (angle > 320 && angle <= 330) {
      y = 30;
    } else if (angle > 330 && angle <= 340) {
      y = 20;
    } else if (angle > 340 && angle < 360) {
      y = 10;
    } else if (angle == 360) {
      y = 0;
    }

    let icon;
    if (online == 'online') {
      icon = 'assets/img/active_rotated_png/online_'+y+'.png';
    } else {
      icon = 'assets/img/inactive_rotated_png/offline_'+y+'.png';
    }
    return icon;
  }

  addMarker(car) { //Add Marker for a single vehicle
    var self = this;
    let online;
    let icon, rotation;

    if (car['online'] == 'ack') {
      online = 'online';  
      // icon = 'assets/img/rsz_car.png';
      // iconoptions.path = ''
    } else {
      online = 'offline';
      // icon = 'assets/img/rsz_car_offline.png'; 
    }

    let lat = car['lat'];
    let lng = car['lng'];
    // iconoptions.rotation = car['course'];
    let coordinates = {lat,lng};

    icon = this.composeIcon(car['course'], online);

    var marker = new google.maps.Marker({
       position: coordinates, // Passing the coordinates
       map:this.map, //Map that we need to add
       draggable: false, // If set to true you can drag the marker
       icon: icon,  
      //  animation: google.maps.Animation.DROP
    });

    //push marker to array
    this.markers.push(marker);

    // console.log(marker);
    
    //popup message
    let contentString = '<p><b>Vehicle: </b>'+car['name']+'</p><p><b>Status: </b>'+online+'</p><p><b>Location: '+location+'</b></p><p><b>Stop Duration: '+car['stop_duration']+' </b><p><p><b>Speed: </b>'+car['speed']+'</p>';
    this.setPopupWindow(marker, lat, lng, car, online);

    // this.map.addListener('idle', self.rotateMarker(rotation))
  }

  rotateMarker(rotation) {
    // document.querySelector('#markerLayer img').style.transform = 'rotate(' + rotation + 'deg)';
    // console.log('changed');
    // google.maps.event.clearListeners(this.map, 'idle');
    // return fail;
  }
  setPopupWindow(marker, lat, lng, car, online) {
    var self = this;
    let location;
    //call location name from the coordinates and pass it to popup info window
    const geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);
    geocoder.geocode({'location': latlng}, function(results, status) {
      if(results!=null) {
        location = results[0].formatted_address;
      }
      let contentString = '<p><b>Vehicle: </b>'+car['name']+'</p><p><b>Status: </b>'+online+'</p><p><b>Location: '+location+'</b></p><p><b>Stop Duration: '+car['stop_duration']+' </b><p><p><b>Speed: </b>'+car['speed']+'</p>';
      var information = new google.maps.InfoWindow({
        content: contentString
      });
      marker.addListener('click', function() {
        information.open(self.map, marker);
      });
    });
  }

  getDevices (hash) {
    this.DS.getDevices(hash).subscribe( data => {
      let all_devices = data[0]['items'];
      this.getCarsOnly(all_devices);
    }, error => {
      console.log(error);
    });
  }

  getCarsOnly(devices) {
    let name;
    this.cars = [];
    for (let i=0; i < devices.length; i++) {
      if (name = devices[i]['name']) {
        let regex = /[A-Z]{3}\s\d{3}\S$/g
        let test = regex.test(name);
        if (test) {
          var car = devices[i];
          // console.log(car);
          this.cars.push(car);
        }
      }
    }
    this.setMapsInterval(); 
    if (history.state.car) { //if is single car, get specific car from devices list and draw it on map
      let single_car = this.getSingleCar(this.carID, this.cars);
      this.initMap(single_car);
      this.map.setCenter({
        lat : single_car.lat,
        lng : single_car.lng
      });
    } else { //if multiple cars draw them on map
      this.initMap(this.cars);
    }
    // console.log(this.cars);
  }

  getSingleCar(id, devices) {
    let car;
    for (let i=0; i < devices.length; i++) {
      if (id == devices[i]['id']) {
        car = devices[i];
        return car;
      }
    }
  }

  setMapsInterval() {
    let self = this;
    this.newMap(); //delete markers from map
    this.markers = []; //reset markers on array
    clearInterval(this.interval); //Reset Interval
    this.interval = setInterval( function() {
      self.getDevices(self.hash);
    }, 10000);
  }

  newMap() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
  }

  initMap(cars) {
    for (let i=0; i < this.cars.length; i++) {
      this.addMarker(this.cars[i]);
    }
  }
}