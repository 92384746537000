import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-landing-navbar',
  templateUrl: './landing-navbar.component.html',
  styleUrls: ['./landing-navbar.component.css']
})
export class LandingNavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('#landing-navbar-toggler').on('click', function () {
      if ($('#landing-sidebar-modal').hasClass('d-none')) {
        $('#landing-sidebar-modal').fadeIn();
        $('#landing-sidebar-modal').removeClass('d-none');
      } else {
        $('#landing-sidebar-modal').fadeOut();
        $('#landing-sidebar-modal').addClass('d-none');
      }
    });
    $('#landing-sidebar-toggler').on('click', function () {
      $('#landing-navbar-toggler').removeClass('toggled');
      if ($('#landing-sidebar-modal').hasClass('d-none')) {
        $('#landing-sidebar-modal').fadeIn();
        $('#landing-sidebar-modal').removeClass('d-none');
      } else {
        $('#landing-sidebar-modal').fadeOut();
        $('#landing-sidebar-modal').addClass('d-none');
      }
    });
    $('.sidebar-link').on('click', function () {
      setTimeout(function () {
        $('#landing-sidebar-toggler').click();
      }, 500);
    });
  }

}
