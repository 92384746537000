<div class="sidebar-right" id="history-sidebar-right">
  <!--date switcher-->
  <div id="sidemap-options" class="sidebar-wrapper">
      <div class="sidebar-inner">
          <div class="">
            <h2 class="card-title text-center"> Vehicle History</h2>
            <h3 style="color: rgb(71, 92, 92);" class="card-title text-center">{{NumberPlate}}</h3>
            <hr>
          </div>
          <div *ngIf="!historyloaded" class=" pl-5 pr-5">

            <p class="card-category text-center">Select a time period.</p>
            <form [formGroup]="HistoryForm">
              <div class="row justify-content-around">
                <!-- <div class="col-md-6 col-lg-3 col-sm-6">
                  <div class="form-group ">
                      <button type="submit" id="history-custom-date-1" class="pull-left date-active btn btn-info history-date-button">1 Hour</button>
                  </div>
                </div> -->
                <div class="col-md-6 col-sm-6 col-lg-3">
                  <div class="form-group">
                    <!-- <label class="bmd-label-floating">Username</label>
                    <input type="text" class="form-control"> -->
                      <button type="submit" id="history-custom-date-24" class="pull-left date-active btn history-date-button btn-info">Today</button>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-3">
                  <div class="form-group">
                    <!-- <label class="bmd-label-floating">Username</label>
                    <input type="text" class="form-control"> -->
                      <button type="submit" id="history-custom-date-yesterday" class="pull-left btn btn-info history-date-button">Yesterday</button>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-3">
                  <div class="form-group">
                    <!-- <label class="bmd-label-floating">Username</label>
                    <input type="text" class="form-control"> -->
                      <button id="history-custom-date" type="submit" class="pull-left btn btn-info history-date-button">Custom</button>
                  </div>
                </div>
              </div>
              <div id="history-date-selector" class="d-none">
                  <div class="row pt-4">
                      <div class="col-md-6 date_from">
                          <div class="form-group">
                          <label class="">Date From:</label>
                          <input type="date" name="from_date" formControlName="from_date" class="form-control">
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="form-group">
                          <label class="time_from_label bmd-label-static">Time From:</label>
                          <input type="time" name="from_time" formControlName="from_time" class="form-control">
                          </div>
                      </div>
                  </div>
                  <div class="pt-4 row">                    
                      <div class="col-md-6">
                          <div class="form-group">
                          <label class="">Date To:</label>
                          <input type="date" name="to_date" formControlName="to_date" class="form-control">
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="form-group">
                            <label class="time_to_label bmd-label-static">Time To:</label>
                          <input type="time" name="to_time" formControlName="to_time" class="form-control">
                          </div>
                      </div>
                  </div>
              </div>
                <hr>
                <p class="card-category mt-3 mb-1 text-center">Events to show</p>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="" for="stops">Stops</label>
                    <input type="checkbox" id="stops" name="stops" formControlName="stops" class="form-control">
                  </div>
                  <div class="form-group">
                    <label class="time_to_label bmd-label-static">Alerts (Overspeed,Geofence etc)</label>
                    <input type="checkbox" id="alerts" name="alerts" formControlName="alerts" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-md-12">
                  <button type="submit" *ngIf="!loadinghistory" (click)="getHistory()" style="width: 100%;" class="btn btn-success">Generate History</button>
                  <button *ngIf="loadinghistory" class="btn btn-disabled" style="width: 100%;">Generating History...</button>
                  <div>
                    <div id="no-data-error" style="display: none;" class="mt-3 alert alert-danger">
                      <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
                      <strong>The vehicle has not moved within that time. Try Adjusting The Time And Try Again</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>
      </div>
  </div>
  <!--date switcher end-->


  <button id="close-history-side" class="btn close-history-side btn-danger">X</button>

  <!--map component-->
    <div id="sidemap-wrapper" style="display: none; width: 100%; height: 100%;">
      <button id="close-history-side2" class="btn close-history-side btn-danger">X</button>
      <app-map></app-map>
    </div>
    <!--map component end-->
</div>