import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { LoggedInService } from 'src/app/_services/logged-in-service.service';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.css']
})
export class HeadersComponent implements OnInit {
  loggedin = false;  
  loading = false;
  submitted = false;
  admin = false;
  accesslevel : any;
  logOut : any;
  username : any;
  
  constructor( private _Activatedroute:ActivatedRoute, private loggedInservice: LoggedInService, private router: Router) { }

  ngOnInit(): void {     
    this.loggedin = this.loggedInservice.loggedin;
    this.logOut = this.loggedInservice.logOut;
    this.admin = this.loggedInservice.admin;
    this.username = this.loggedInservice.username;
    this.accesslevel = this.loggedInservice.accesslevel;
    this.router.events.subscribe((evt) => {
    if (!(evt instanceof NavigationEnd)) {
        return;
    }
    window.scrollTo(0, 0)
  });
  }

}
