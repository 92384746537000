import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  toggleSideBar = new EventEmitter();
  toggleHistorySideBar = new EventEmitter();
  toggleMapSidebar = new EventEmitter();
  changeCurrentPdf = new EventEmitter();
  closePdfViewer = new EventEmitter();
  resetMap = new EventEmitter();
  pdfLoaded = new EventEmitter();
  constructor() { }
}
