<footer class="footer">
    <div class="container-fluid">
      <nav class="float-left">
        <ul>
          <li>
            <a href="">
              How It Works
            </a>
          </li>
          <li>
            <a href="">
              About Us
            </a>
          </li>
          <li>
            <a href="">
              Partner With Us
            </a>
          </li>
          <li>
            <a href="">
              Support
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright float-right">
        &copy;
        <script>
          document.write(new Date().getFullYear())
        </script> A product of <a href="https://www.zukabit.com" target="_blank">Zukabit Solutions</a> 
      </div>
    </div>
  </footer>