import { Component, ViewChild, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { config } from '../../_helpers/global';
import { BroadcastService } from 'src/app/_services/broadcast.service';
import { Router } from '@angular/router';
import { DevicesService } from 'src/app/_services/devices.service';
import { error } from 'protractor';
import { LoggedInService } from 'src/app/_services/logged-in-service.service';
import { on } from 'process';
@Component({
  selector: 'app-reports-side-bar',
  templateUrl: './reports-side-bar.component.html',
  styleUrls: ['./reports-side-bar.component.css']
})

export class ReportsSideBarComponent implements OnInit {
  
  hash :any;
  ReportsForm: FormGroup;
  loading = false;
  submitted = false;
  pdfLoading = true;
  radiocheck = true;
  loadingreport = false;
  reportloaded = false;
  header: any;
  tagline: any;
  devices: any;
  cars = [];
  anchor_tag : any;
  carID : any;
  isPDF : any;

  constructor(private DS: DevicesService, private fb: FormBuilder, private router: Router, private BS: BroadcastService, private LS: LoggedInService) {
    this.BS.toggleSideBar.subscribe(data => {
      this.header = data.header; //set header
      this.tagline = data.tagline; //set tagline
      if (data.device) {
        this.carID = data.device.id;
      }
      this.updateReportSelect(data);
      this.updateDevicesSelect(data);
      $('#sidebar-right').addClass('active');
      $('#reports-background-modal').show().addClass('active');
    });
    this.BS.pdfLoaded.subscribe(data => {
      this.pdfLoading = false;
    });
  }

  ngOnInit(): void {
    this.checkLoggedIn();

    this.ReportsForm = this.fb.group({
      date_from: ['', Validators.required],
      date_to: ['', Validators.required],
      devices: ['', Validators.required],
      report_type: ['', Validators.required],
      export_as: ['', Validators.required]
    });

    var self = this;
    // this.getDevices(this.hash);
    this.setDefaultValues();

    //toggle date on demand
    $('body').on('click', '.date-button',function (event) {
      let thisone = this;
      self.toggleDate(event, thisone);
    });

    //hide sidebar on click away
    $('body').on('click', '#reports-background-modal', function (event) {
      self.hideSideBar(event);
      setTimeout(function(){
        self.resetSideBar();
      }, 450);
    });

    //close report button
    $('body').on('click', '#close-report-side', function (event) {
      $('#sidebar-right').removeClass('active');
      $('#reports-background-modal').removeClass('active');
      setTimeout(function(){
        $('#reports-background-modal').hide();
        self.resetSideBar();
      }, 450);
    });
  }

  updateDevicesSelect(data) {
    var self = this;
    //if device is defined update select list
    if (data.device) {
      $('#devices_select_wrapper').hide();
      this.devices = [];
      this.devices.push(data.device);
      self.ReportsForm.patchValue({ devices: data.device.id });
      var select = $('#sidebar-select-devices').select2();
      select.val(data.device.id); // Select the option with a value of id
      select.trigger('change'); // Notify any JS components that the value changed
    } else {
      $('#devices_select_wrapper').show();
    }
  }

  checkLoggedIn() {
    if (this.LS.checkStatus() != 0) {
      this.hash = this.LS.checkStatus();
      this.getDevices(this.hash);
    }
  }

  updateReportSelect(data) {
    if (data.report_type) { //if report type is defined update the select input
      $('#report_type_wrapper').hide();
      // this.ReportsForm.patchValue({ report_type: data.report_type });
    } else {
      $('#report_type_wrapper').show();
    }
  }

  replaceDate(id) {
    if (id == "custom-date-24") {
      //1 day earlier
      this.ReportsForm.patchValue({ date_from: this.formatDate(new Date(Date.now() - 864e5)) });
    } else if (id == "custom-date-7") {
      //7 days earlier
      this.ReportsForm.patchValue({ date_from: this.formatDate(new Date(Date.now() - 604800000)) });
    } else if (id == "custom-date-30") {
      this.ReportsForm.patchValue({ date_from: this.formatDate(new Date(Date.now() - 2592000000)) });
    }
  }

  setDefaultValues() {
    this.ReportsForm.patchValue({ report_type: '1', export_as: 'pdf', date_to: this.formatDate(new Date()), date_from: this.formatDate(new Date(Date.now() - 864e5)) });
  }

  hideSideBar(event) {
    if (!$(event.target).closest('#sidebar-right').length && !$(event.target).is('#sidebar-right')) {
      $('#sidebar-right').removeClass('active');
      $('#reports-background-modal').removeClass('active');
      setTimeout(function(){
        $('#reports-background-modal').hide();
      }, 450);
    }
  }

  toggleDate(event, thisone) {
    if (event.target.id == "custom-date") {
      $('#date-selector').removeClass('d-none');
    } else {
      $('#date-selector').addClass('d-none');
    }
    for (let i = 0; i < $('.date-button').length; i++) {
      let btnid = $('.date-button')[i].id;
      $('#' + btnid).removeClass('date-active');
    }
    this.replaceDate(event.target.id);
    $(thisone).addClass('date-active');
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  getDevices(hash) {
    this.DS.getDevices(hash).subscribe(data => {
      // console.log(data);
      let all_devices = data[0]['items'];
      this.devices = all_devices;
      // setTimeout(function(){
        $('#sidebar-select-devices').select2();
      // },500);

      // $(document).ready(function() {
        // $('#sidebar-select-devices').selectpicker();
        // });
    }, error => {
      console.log(error);
    });
  }

  getReport() {
    // console.log(this.ReportsForm.value);
    this.submitted = true;
    this.checkValidity();
    let id_array = this.getDevicesIds();
    let api_call_string = this.constructString(id_array);
    // console.log(api_call_string);
    this.loadingreport = true;
    this.isPDF = this.ReportsForm.value.export_as;
    this.DS.getReport(api_call_string).subscribe( data=> {
      this.loadingreport = false;
      if (data.status == 3) {
        this.reportloaded = true;
        this.anchor_tag = data.url;
      }
      // console.log(data);
    }, error => {
      this.loadingreport = false;
      console.log('error');
      console.log(error);      
    });
    // this.submitted = false;
  }

  openPdf(pdf) {
    this.BS.changeCurrentPdf.emit({pdf : this.anchor_tag});
    $('#pdf-viewer').css({'z-index' : 1001, 'position' : 'fixed', 'height' :  '100%', 'width': '100%'});    
  }

  closePdf() {
    // $('#close-pdf-viewer').on('click', function(){
      $('#pdf-viewer').css({'z-index' : -1, 'position' : 'absolute', 'height' :  '1%', 'width': '1%'});
      // this.resetSideBar();
      // $('#pdf-viewer').fadeOut('fast');
    // });
  }

  checkValidity() {

  }

  getDevicesIds () {
    let id_array = [];
    if(this.carID) {
      id_array.push(parseInt(this.carID));
      // console.log();
    } else {
      var select = $('#sidebar-select-devices').select2('data');
      for (let i=0; i < select.length; i++) {
        var id = select[i].element.attributes[2].value;
        id_array.push(parseInt(id));
      }
    }
    return id_array;
  }

  resetSideBar() {
    // this.ngOnInit();
    this.setDefaultValues();
    this.loading = false;
    this.submitted = false;
    this.loadingreport = false;
    this.reportloaded = false;
    this.radiocheck = true;
    // this.header = '';
    // this.tagline = '';
    // this.devices ;
    // this.cars = [];
    // this.carID = '';
    // this.anchor_tag = "";
  }

  constructString(id_array) {
    let user_api_hash = this.hash;
    let lang = 'en';
    let type = this.ReportsForm.value.report_type;
    let speed_limit;
    if (type == 5) {
      speed_limit = 80;
    }
    let devices = id_array;
    let format = this.ReportsForm.value.export_as;
    let date_from = this.ReportsForm.value.date_from;
    let date_to = this.ReportsForm.value.date_to;
    let variables = {user_api_hash, lang, type, devices,format, date_from, date_to, speed_limit};
    let variables_string = JSON.stringify(variables);
    
    // let variables_string = 'user_api_hash='+variables.user_api_hash+'&lang=en'+'&type='+variables.type+'&devices='+variables.devices+'&format='+variables.format+'&date_from='+variables.date_from+'&date_to='+variables.date_to;
    return variables_string;
  }
}