import { Injectable } from '@angular/core';
import { config } from '../_helpers/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private http: HttpClient) { }

  get_user_data (hash){
    return this.http.get<any>(`${config.apiUrl}/get_user_data?user_api_hash=`+hash+`&lang=en`);
  }
}
