<div class="wrapper ">
    <!--sidebar-->
    <app-side-bar></app-side-bar>
    <!--sidebar End-->

    <div class="main-panel">
      <!-- Navbar -->
        <!-- <app-nav-bar></app-nav-bar> -->
      <!-- End Navbar -->
        <!-- Error Page -->
        <div class="error border ground-color ">
            <div class="container-floud">
                <div class="col-xs-12 ground-color text-center">
                    <div class="container-error-404">
                        <div class="clip"><div class="shadow"><span class="digit thirdDigit"></span></div></div>
                        <div class="clip"><div class="shadow"><span class="digit secondDigit"></span></div></div>
                        <div class="clip"><div class="shadow"><span class="digit firstDigit"></span></div></div>
                        <div class="msg">OH!<span class="triangle"></span></div>
                    </div>
                    <h2 class="h1">Sorry! Page not found</h2>
                </div>
            </div>
        </div>
    <!-- Error Page -->

      <!--footer-->
        <app-footer></app-footer>
      <!--footer END-->
    </div>
  </div>